import React from 'react';
import { Container, Row, Col, Card, Button, Accordion } from 'react-bootstrap';
import { Download, Users, Mic, CheckCircle, Camera } from 'lucide-react';
import VideoPlayer from './VideoPlayer';

import { PageTitle } from '../common/PageTitle';

const JoinProject = () => {
  return (
    <>
    <PageTitle title="Dołącz" />
    <Container>
      <Row className="mb-5">
        <Col md={6}>
          <h1 className="mb-4">Dołącz do projektu</h1>
          <p className="lead">
            Zachowanie historii naszej gminy to wspólne zadanie. Każdy może się włączyć 
            w tworzenie Biblioteki Historii Mówionej – jako rozmówca, wolontariusz lub dokumentalista.
          </p>
        </Col>
        <Col md={6}>
	     <img
                  src="/img/pbhm-logo-3.svg"
                  alt="PBHM logo"
                  className="w-75 img-fluid"
                />
        </Col>
      </Row>

      {/* Główne sposoby zaangażowania */}
      <Row className="mb-5">
        <Col md={4} className="mb-4">
          <Card className="h-100 shadow-sm bg-secondary-subtle">
            <Card.Body className="text-center">
              <div className="mb-3">
                <Mic size={48} className="text-primary" />
              </div>
              <Card.Title>Podziel się historią</Card.Title>
              <Card.Text>
                Masz wspomnienia związane z gminą? Opowiedz nam swoją historię.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="h-100 shadow-sm bg-secondary-subtle">
            <Card.Body className="text-center">
              <div className="mb-3">
                <Users size={48} className="text-primary" />
              </div>
              <Card.Title>Zostań wolontariuszem</Card.Title>
              <Card.Text>
                Pomóż nam w nagrywaniu i dokumentowaniu historii mieszkańców.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="h-100 shadow-sm bg-secondary-subtle">
            <Card.Body className="text-center">
              <div className="mb-3">
                <Camera size={48} className="text-primary" />
              </div>
              <Card.Title>Udostępnij materiały</Card.Title>
              <Card.Text>
                Masz stare zdjęcia lub dokumenty? Pomóż nam budować archiwum.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Informacje dla wolontariuszy */}
      <Row className="mb-5">
        <Col lg={8}>
          <h2 className="mb-4">Dla wolontariuszy</h2>
          <Accordion className="mb-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Jak przeprowadzić wywiad?
              </Accordion.Header>
              <Accordion.Body>
                <ol className="ps-3">
                  <li className="mb-2">Przygotuj się do rozmowy – zapoznaj się z historią miejsca i okresu</li>
                  <li className="mb-2">Wybierz spokojne miejsce na nagranie</li>
                  <li className="mb-2">Rozmowę rozpocznij od prośby o przedstawienie się rozmówcy</li>
                  <li className="mb-2">Używaj otwartych pytań zachęcających do szerszych wypowiedzi</li>
                  <li className="mb-2">Pozwól rozmówcy swobodnie się wypowiedzieć</li>
                  <li className="mb-2">Dopytuj o szczegóły, ale nie przerywaj</li>
                  <li className="mb-2">Zapisz podstawowe informacje o rozmówcy</li>
                  <li className="mb-2">Poproś rozmówcę o możliwość zrobienia mu zdjęcia</li>
                </ol>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Sprzęt i technika nagrywania
              </Accordion.Header>
              <Accordion.Body>
                <h5>Wymagania techniczne:</h5>
                <ul className="ps-3">
                  <li>Dyktafon lub telefon z dobrym mikrofonem
	  		(dysponujemy sprzętem do wykorzystania)</li>
                  <li>Dodatkowa bateria/powerbank</li>
                  <li>Statyw do telefonu (opcjonalnie)</li>
                  <li>Notatnik</li>
                </ul>
                <h5 className="mt-4">Ustawienia nagrywania:</h5>
                <ul className="ps-3">
                  <li>Format: WAV lub MP3 (min. 320 kbps)</li>
                  <li>Częstotliwość próbkowania: min. 44.1 kHz</li>
                  <li>Sprawdź poziom nagrywania przed rozpoczęciem</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Dokumentacja i formularze
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-column gap-3">
                  <Button
			as="a" href="/doc/oswiadczenie.pdf" target="_blank" rel="noreferrer"
			variant="outline-primary" className="d-flex align-items-center gap-2">
                    <Download size={20} />
                    Formularz zgody na nagrywanie
                  </Button>
                  <Button
			as="a" href="/doc/karta.pdf" target="_blank" rel="noreferrer"
			variant="outline-primary" className="d-flex align-items-center gap-2">
                    <Download size={20} />
                    Metryczka wywiadu
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Materiały i informacje
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-column gap-3">
	  <p>Wiele użytecznych informacji, materiałów i&nbsp;dokumentów
	  można znaleźć na stronach <a target="_blank" rel="noreferrer"
	  href="https://cas.org.pl">Centrum Archiwistyki Społecznej</a></p>
	  <p>Polecamy zwłaszcza dostępne nieodpłatnie książki:</p>
	  <ul>
	<li><a target="_blank" rel="noreferrer"
	  href="https://cas.org.pl/podrecznik/">
	  Podręcznik dla archiwistów społecznych</a> – 
	  w&nbsp;którym jest rozdział dotyczący historii mówionej</li>
	<li><a target="_blank" rel="noreferrer"
	  href="https://cas.org.pl/podrecznik-do-historii-mowionej/">
	  Podręcznik do historii mówionej</a></li> – w&nbsp;całości poświęcony historii mówionej
	  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

	     <VideoPlayer className="my-5"
              videoSrc="/videos/rozmowa-poczatek.mp4"
              posterSrc="/videos/rozmowa-poczatek.jpg"
              maxWidth="500px"
              aspectRatio="16:9"
              background="bg-secondary-subtle"
              text="text-dark"
              head="Początek rozmowy"
              title="Przykładowy sposób rozpoczęcia wywiadu"
            />


        </Col>
        <Col lg={4}>
          <Card className="bg-light border-0">
            <Card.Body>
              <h4 className="mb-4">Jak zacząć?</h4>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex gap-3">
                  <CheckCircle className="text-primary flex-shrink-0" />
                  <div>Skontaktuj się z nami</div>
                </div>
                <div className="d-flex gap-3">
                  <CheckCircle className="text-primary flex-shrink-0" />
                  <div>Weź udział w szkoleniu wprowadzającym</div>
                </div>
                <div className="d-flex gap-3">
                  <CheckCircle className="text-primary flex-shrink-0" />
                  <div>Pobierz i zapoznaj się z materiałami</div>
                </div>
                <div className="d-flex gap-3">
                  <CheckCircle className="text-primary flex-shrink-0" />
                  <div>Przeprowadź pierwszy wywiad pod opieką koordynatora</div>
                </div>
                <Button as="a" href="/contact" variant="primary" className="mt-3">
                  Zgłoś się do projektu
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default JoinProject;
