// content/terms.js
export const termsContent = `
  <div class="container fs-5 mx-auto px-4 py-8">
    <div class="prose max-w-none terms-content">
      <h1 class="text-center mb-5">Regulamin korzystania z&nbsp;systemu Pomiechowskiej Biblioteki Historii Mówionej</h1>

      <h2 class="text-2xl font-semibold mt-4">§1. Postanowienia ogólne</h2>
      <ol>
        <li>Niniejszy regulamin określa zasady korzystania z&nbsp;systemu Pomiechowskiej Biblioteki Historii Mówionej, zwanego dalej "Systemem".</li>
        <li>Administratorem Systemu jest Biblioteka Publiczna Gminy Pomiechówek z&nbsp;siedzibą przy ul. Jana Kilińskiego 3, 05-180 Pomiechówek.</li>
        <li>System służy do udostępniania materiałów historycznych w&nbsp;formie cyfrowej, w&nbsp;tym nagrań audio, wideo, fotografii i&nbsp;dokumentów.</li>
      </ol>

      <h2 class="text-2xl font-semibold mt-4">§2. Definicje</h2>
      <ol>
        <li>Użytkownik - osoba fizyczna posiadająca konto w&nbsp;Systemie.</li>
        <li>Konto - zbiór zasobów i&nbsp;uprawnień przypisanych Użytkownikowi.</li>
        <li>Materiały - wszelkie treści cyfrowe udostępniane w&nbsp;Systemie.</li>
        <li>Narrator - osoba, której relacja została zarejestrowana w&nbsp;ramach projektu historii mówionej.</li>
      </ol>

      <h2 class="text-2xl font-semibold mt-4">§3. Zasady zakładania i&nbsp;prowadzenia konta</h2>
      <p>1. Konto w&nbsp;Systemie może założyć osoba, która:</p>
      <ul class="list-disc pl-6">
        <li>ukończyła 16 lat</li>
        <li>posiada adres e-mail</li>
        <li>zaakceptowała regulamin</li>
        <li>wyraziła zgodę na przetwarzanie danych osobowych</li>
      </ul>

      <p class="mt-3">2. Proces rejestracji wymaga:</p>
      <ul class="list-disc pl-6">
        <li>podania imienia i&nbsp;nazwiska</li>
        <li>podania adresu e-mail</li>
        <li>utworzenia hasła</li>
        <li>weryfikacji adresu e-mail poprzez link aktywacyjny</li>
      </ul>

      <h2 class="text-2xl font-semibold mt-4">§4. Zasady korzystania z&nbsp;materiałów</h2>
      <p>1. Dostęp do materiałów jest możliwy wyłącznie dla zalogowanych użytkowników.</p>
      
      <p class="mt-3">2. Użytkownik ma prawo do:</p>
      <ul class="list-disc pl-6">
        <li>przeglądania udostępnionych materiałów</li>
        <li>odtwarzania nagrań audio i&nbsp;wideo</li>
        <li>pobierania materiałów oznaczonych jako dostępne do pobrania</li>
      </ul>

      <h2 class="text-2xl font-semibold mt-4">§5. Prawa autorskie i&nbsp;licencje</h2>
      <ol>
        <li>Wszelkie prawa do materiałów udostępnianych w&nbsp;Systemie należą do Biblioteki Publicznej Gminy Pomiechówek lub innych uprawnionych podmiotów.</li>
        <li>Materiały są udostępniane na zasadach licencji niewyłącznej, nieprzenoszalnej i&nbsp;niekomercyjnej.</li>
        <li>Wykorzystanie materiałów w&nbsp;publikacjach, pracach naukowych lub projektach edukacyjnych wymaga pisemnej zgody administratora.</li>
      </ol>

      <h2 class="text-2xl font-semibold mt-4">§6. Naruszenia i&nbsp;sankcje</h2>
      <p>1. Za naruszenie regulaminu uznaje się w&nbsp;szczególności:</p>
      <ul class="list-disc pl-6">
        <li>udostępnianie materiałów osobom nieuprawnionym</li>
        <li>wykorzystywanie materiałów w&nbsp;celach komercyjnych bez zgody</li>
        <li>naruszanie praw autorskich</li>
      </ul>

      <h2 class="text-2xl font-semibold mt-4">§7. Postanowienia końcowe</h2>
      <ol>
        <li>Administrator zastrzega sobie prawo do zmiany regulaminu.</li>
        <li>O&nbsp;zmianach regulaminu użytkownicy będą informowani poprzez System oraz pocztą elektroniczną.</li>
        <li>Kontynuowanie korzystania z&nbsp;Systemu po wprowadzeniu zmian oznacza ich akceptację.</li>
        <li>Regulamin wchodzi w&nbsp;życie z&nbsp;dniem 1 stycznia 2025 roku.</li>
      </ol>
    </div>
  </div>
`;
