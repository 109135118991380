import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FileText } from 'lucide-react';

const MediaLightboxModal = ({ 
  show, 
  item, 
  onHide, 
  getFileUrl 
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {item?.title || item?.filename}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center p-0">
        {item?.isImage ? (
          <img
            src={getFileUrl(item)}
            alt={item.title}
            className="img-fluid"
            style={{ maxHeight: '80vh' }}
          />
        ) : item?.isPDF && (
          <div className="p-4 d-flex flex-column align-items-center">
            <FileText size={64} className="text-primary mb-3" />
            <Button
              variant="primary"
              href={getFileUrl(item)}
              target="_blank"
            >
              Otwórz PDF
            </Button>
          </div>
        )}
      </Modal.Body>
      {item?.description && (
        <Modal.Footer className="bg-light">
          <p className="text-muted mb-0">{item.description}</p>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default MediaLightboxModal;
