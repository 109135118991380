// components/VideoPlayer.js
import React from 'react';
import { Container } from 'react-bootstrap';

function VideoPlayer({
  videoSrc,
  posterSrc,
  title,
  head,
  width,
  height,
  maxWidth,
  background="bg-dark",
  text="text-light",
  aspectRatio,
  autoplay = false,
  muted = false // nowy parametr do kontroli wyciszenia
}) {
  const calculatePaddingTop = (ratio) => {
    if (!ratio) return '56.25%';

    let ratioParts;
    if (ratio.includes(':')) {
      ratioParts = ratio.split(':').map(Number);
      return `${(ratioParts[1] / ratioParts[0]) * 100}%`;
    } else if (!isNaN(ratio)) {
      return `${(1 / parseFloat(ratio)) * 100}%`;
    }
    return '56.25%';
  };

  const containerStyle = {
    maxWidth: maxWidth || 'auto',
    width: width || '100%',
    margin: '0 auto'
  };

  const videoWrapperStyle = {
    position: 'relative',
    width: '100%',
    paddingTop: height ? 'auto' : calculatePaddingTop(aspectRatio),
    height: height || 'auto'
  };

  const videoStyle = {
    position: height ? 'relative' : 'absolute',
    top: height ? 'auto' : '0',
    left: height ? 'auto' : '0',
    width: '100%',
    height: height ? height : '100%',
    objectFit: 'contain'
  };

  return (
    <Container className={`video-section mb-5 px-5 ${background}`}>
      <h3 className={`text-center pt-2 mb-4 ${text}`}>{head}</h3>
      <div style={containerStyle}>
        <div style={videoWrapperStyle}>
          <video
            className="rounded shadow"
            controls
            poster={posterSrc}
            preload="metadata"
            style={videoStyle}
            autoPlay={autoplay}
            muted={muted}
          >
            <source src={videoSrc} type="video/mp4" />
            Twoja przeglądarka nie obsługuje odtwarzania wideo.
          </video>
        </div>
        {title && (
          <p className={`text-center my-2 py-2 ${text}`}>
            {title}
          </p>
        )}
      </div>
    </Container>
  );
}

export default VideoPlayer;
