import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Button, Form, Badge } from 'react-bootstrap';
import { Edit2, Save, ArrowDownUp, Tags, X, Search, ChevronLeft, ChevronRight } from 'lucide-react';
import AddTagsModal from './AddTagsModal';

const TranscriptionEditor = ({
  transcription,
  currentSubtitle,
  editingSubtitle,
  hasChanges,
  onSubtitleClick,
  onEditToggle,
  onSubtitleEdit,
  onMergeSubtitles,
  onSave,
  recordingId,
  transcriptFile,
  isLocked,
  userEmail,
  recording
}) => {
  const transcriptionRef = useRef(null);
  const autoSaveTimerRef = useRef(null);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [selectedSubtitle, setSelectedSubtitle] = useState(null);
  const [subtitleTags, setSubtitleTags] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [currentMatchIndex, setCurrentMatchIndex] = useState(-1);
  const [matches, setMatches] = useState([]);
  const [autoSaveStatus, setAutoSaveStatus] = useState('');

  const canEditText = !isLocked || (isLocked && userEmail === recording?.proofreading?.email);

  useEffect(() => {
    if (hasChanges && canEditText) {
      if (autoSaveTimerRef.current) {
        clearTimeout(autoSaveTimerRef.current);
      }

      autoSaveTimerRef.current = setTimeout(async () => {
        try {
          await onSave();
          setAutoSaveStatus('Automatycznie zapisano zmiany');
          setTimeout(() => {
            setAutoSaveStatus('');
          }, 3000);
        } catch (error) {
          console.error('Auto-save failed:', error);
        }
      }, 5000);
    }

    return () => {
      if (autoSaveTimerRef.current) {
        clearTimeout(autoSaveTimerRef.current);
      }
    };
  }, [hasChanges, onSave, canEditText]);

  const loadSubtitleTags = useCallback(async () => {
    try {
      const response = await fetch(`/api/tags.php?recording_id=${recordingId}&transcript_file=${transcriptFile}`);
      if (!response.ok) throw new Error('Nie udało się załadować tagów');
      const data = await response.json();

      const tagsByStartTime = {};
      data.data.forEach(tag => {
        if (!tagsByStartTime[tag.start_time]) {
          tagsByStartTime[tag.start_time] = [];
        }
        tagsByStartTime[tag.start_time].push(tag);
      });

      setSubtitleTags(tagsByStartTime);
    } catch (error) {
      console.error('Error loading tags:', error);
    }
  }, [recordingId, transcriptFile]);

  useEffect(() => {
    loadSubtitleTags();
  }, [loadSubtitleTags]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const scrollToSubtitle = useCallback((subtitle) => {
    const element = document.getElementById(`subtitle-${subtitle.id}`);
    if (element && transcriptionRef.current) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      onSubtitleClick(subtitle);
    }
  }, [onSubtitleClick]);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    if (!query.trim()) {
      setMatches([]);
      setCurrentMatchIndex(-1);
      return;
    }

    const matchingSubtitles = transcription.reduce((acc, subtitle, index) => {
      if (subtitle.text.toLowerCase().includes(query.toLowerCase())) {
        acc.push({ ...subtitle, index });
      }
      return acc;
    }, []);

    setMatches(matchingSubtitles);
    if (matchingSubtitles.length > 0) {
      setCurrentMatchIndex(0);
      const firstMatch = matchingSubtitles[0];
      scrollToSubtitle(firstMatch);
    }
  }, [transcription, scrollToSubtitle]);

  const handleNextMatch = useCallback(() => {
    if (matches.length === 0) return;
    const nextIndex = (currentMatchIndex + 1) % matches.length;
    setCurrentMatchIndex(nextIndex);
    scrollToSubtitle(matches[nextIndex]);
  }, [matches, currentMatchIndex, scrollToSubtitle]);

  const handlePreviousMatch = useCallback(() => {
    if (matches.length === 0) return;
    const prevIndex = currentMatchIndex <= 0 ? matches.length - 1 : currentMatchIndex - 1;
    setCurrentMatchIndex(prevIndex);
    scrollToSubtitle(matches[prevIndex]);
  }, [matches, currentMatchIndex, scrollToSubtitle]);

  const highlightText = (text, query) => {
    if (!query.trim()) return text;

    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === query.toLowerCase() ? (
            <mark key={index} className="bg-warning">{part}</mark>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const handleOpenTagsModal = (subtitle) => {
    setSelectedSubtitle(subtitle);
    setShowTagsModal(true);
  };

  const handleTagsModalClose = (changed) => {
    setShowTagsModal(false);
    if (changed) {
      loadSubtitleTags();
    }
  };

  const handleRemoveTag = async (tag, startTime) => {
    if (!window.confirm(`Czy na pewno chcesz usunąć tag "${tag.name}" z tego fragmentu?`)) {
      return;
    }

    try {
      const response = await fetch('/api/tags.php', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tag_id: tag.id,
          recording_id: recordingId,
          transcript_file: transcriptFile,
          start_time: startTime
        })
      });

      if (!response.ok) throw new Error('Nie udało się usunąć tagu');

      const data = await response.json();
      if (!data.success) throw new Error(data.error || 'Nie udało się usunąć tagu');

      loadSubtitleTags();
    } catch (error) {
      console.error('Error removing tag:', error);
      alert('Wystąpił błąd podczas usuwania tagu');
    }
  };

  return (
  <div className="d-flex flex-column h-100 mx-0 mx-md-5 px-0 px-md-5">
      {/* Header section */}
      <div className="flex-shrink-0">
        <div className="d-flex justify-content-between align-items-center my-2">
          <div className="mb-0 fw-black fs-5">Transkrypcja</div>
          <div className="d-flex align-items-center gap-3">
            {isLocked && (
<span className={`p-2 fs-5 fw-black rounded d-flex align-items-center ${userEmail === recording?.proofreading?.email ? 'text-success' : 'text-danger'}`}>
  {userEmail === recording?.proofreading?.email
    ? "zablokowana przez ciebie"
    : `zablokowana przez: ${recording?.proofreading?.email}`
  }
</span>
            )}
            {autoSaveStatus && (
              <small className="text-muted">{autoSaveStatus}</small>
            )}
            {canEditText && (
              <Button
                variant="primary"
                size="sm"
                onClick={onSave}
                disabled={!hasChanges}
              >
                <Save size={16} className="me-2" />
                Zapisz zmiany
              </Button>
            )}
          </div>
        </div>

        {/* Search section */}
        <div className="position-relative mb-3 d-flex gap-2">
          <div className="position-relative flex-grow-1">
            <Form.Control
              type="text"
              placeholder="Wyszukaj w transkrypcji..."
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              className="pe-4"
            />
            <Search
              size={16}
              className="position-absolute"
              style={{
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: '#6c757d'
              }}
            />
          </div>
          {matches.length > 0 && (
            <div className="d-flex gap-2 align-items-center">
              <small className="text-muted">
                {currentMatchIndex + 1} z {matches.length}
              </small>
              <div className="d-flex gap-1">
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={handlePreviousMatch}
                  title="Poprzedni wynik"
                >
                  <ChevronLeft size={16} />
                </Button>
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={handleNextMatch}
                  title="Następny wynik"
                >
                  <ChevronRight size={16} />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Transcription content - scrollable area */}
      <div
        ref={transcriptionRef}
        className="flex-grow-1 overflow-auto fs-5"
        style={{
          padding: '10px',
          border: '1px solid #dee2e6',
          borderRadius: '4px',
	  height: 'calc(100vh - 476px)',
          minHeight: 0
        }}
      >
        {transcription.map((subtitle, index) => (
          <div
            key={subtitle.id}
            id={`subtitle-${subtitle.id}`}
            className={`p-2 mb-2 rounded ${
              currentSubtitle?.id === subtitle.id
                ? 'bg-primary text-white'
                : 'bg-light'
            }`}
            onClick={() => onSubtitleClick(subtitle)}
            style={{ cursor: 'pointer' }}
          >
            <div className="d-flex justify-content-between align-items-start mb-1">
              <div
                className={`small ${
                  currentSubtitle?.id === subtitle.id
                    ? 'text-white-50'
                    : 'text-muted'
                }`}
              >
                {formatTime(subtitle.start)} - {formatTime(subtitle.end)}
              </div>
              <div className="d-flex gap-1">
                {canEditText && index < transcription.length - 1 && (
                  <Button
                    variant={currentSubtitle?.id === subtitle.id ? 'outline-light' : 'outline-secondary'}
                    size="sm"
                    className="p-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      onMergeSubtitles(subtitle.id);
                    }}
                    title={
                      subtitleTags[subtitle.start]?.length > 0 || subtitleTags[transcription[index + 1].start]?.length > 0
                        ? "Nie można połączyć napisów z przypisanymi tagami"
                        : "Połącz z następnym"
                    }
                    disabled={subtitleTags[subtitle.start]?.length > 0 || subtitleTags[transcription[index + 1].start]?.length > 0}
                  >
                    <ArrowDownUp size={14} />
                  </Button>
                )}
                <Button
                  variant={currentSubtitle?.id === subtitle.id ? 'outline-light' : 'outline-secondary'}
                  size="sm"
                  className="p-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenTagsModal(subtitle);
                  }}
                  title="Zarządzaj indeksami"
                >
                  <Tags size={14} />
                </Button>
                {canEditText && (
                  <Button
                    variant={currentSubtitle?.id === subtitle.id ? 'outline-light' : 'outline-secondary'}
                    size="sm"
                    className="p-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditToggle(editingSubtitle === subtitle.id ? null : subtitle.id);
                    }}
                  >
                    <Edit2 size={14} />
                  </Button>
                )}
              </div>
            </div>
            {editingSubtitle === subtitle.id && canEditText ? (
              <Form.Control
                as="textarea"
                rows={3}
                value={subtitle.text}
                onChange={(e) => onSubtitleEdit(subtitle.id, e.target.value)}
                className="mb-2 edit-srt"
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              <>
                <div>{highlightText(subtitle.text, searchQuery)}</div>
                {subtitleTags[subtitle.start]?.length > 0 && (
                  <div className="mt-2 d-flex flex-wrap gap-1">
                    {subtitleTags[subtitle.start].map(tag => (
                      <Badge
                        key={tag.id}
                        bg={tag.type === 'keyword' ? 'info' : 'success'}
                        className="px-2 py-1 d-flex align-items-center gap-1"
                        style={{ cursor: 'default' }}
                      >
                        <span>{tag.name}</span>
                        <X
                          size={14}
                          className="ms-1"
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveTag(tag, subtitle.start);
                          }}
                        />
                      </Badge>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>

      {/* Tags Modal */}
      {showTagsModal && selectedSubtitle && (
        <AddTagsModal
          show={showTagsModal}
          onHide={handleTagsModalClose}
          recordingId={recordingId}
          transcriptFile={transcriptFile}
          startTime={selectedSubtitle.start}
          currentTags={subtitleTags[selectedSubtitle.start] || []}
        />
      )}
    </div>
  );
};

export default TranscriptionEditor;
