import React, { useState, useEffect } from 'react';
import { Form, Spinner, Modal, InputGroup, Tab, Tabs, Badge, Button } from 'react-bootstrap';
import { Search } from 'lucide-react';
import TagRecordingsList from './TagRecordingsList';

import { PageTitle } from '../common/PageTitle';

const TagsManagement = () => {
  const [tags, setTags] = useState({ keywords: [], persons: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [recordings, setRecordings] = useState([]);
  const [recordingsDetails, setRecordingsDetails] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('keywords');
  const [isLoadingRecordings, setIsLoadingRecordings] = useState(false);
  const [editedTagName, setEditedTagName] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    loadAllTags();
  }, []);

  const loadAllTags = async () => {
    try {
      const [keywordsResponse, personsResponse] = await Promise.all([
        fetch('/api/tags.php?type=keyword'),
        fetch('/api/tags.php?type=person')
      ]);

      if (!keywordsResponse.ok || !personsResponse.ok) {
        throw new Error('Nie udało się załadować tagów');
      }

      const [keywordsData, personsData] = await Promise.all([
        keywordsResponse.json(),
        personsResponse.json()
      ]);

      setTags({
        keywords: keywordsData.data,
        persons: personsData.data
      });
    } catch (err) {
      setError('Wystąpił błąd podczas ładowania tagów: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const fetchRecordingDetails = async (recordingId) => {
    try {
      const response = await fetch(`/api/recordings/${recordingId}`, {
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Nie udało się pobrać szczegółów nagrania');
      const result = await response.json();
      return result.data;
    } catch (err) {
      console.error('Błąd podczas pobierania szczegółów nagrania:', err);
      return null;
    }
  };

  const handleTagClick = async (tag) => {
    setSelectedTag(tag);
    setEditedTagName(tag.name);
    setIsLoadingRecordings(true);

    try {
      const response = await fetch(`/api/tags.php?tag_id=${tag.id}`);
      if (!response.ok) throw new Error('Nie udało się załadować nagrań');
      const data = await response.json();

      const groupedRecordings = data.data.reduce((acc, item) => {
        if (!acc[item.recording_id]) {
          acc[item.recording_id] = {
            id: item.recording_id,
            timestamps: []
          };
        }
        acc[item.recording_id].timestamps.push(formatTime(item.start_time));
        return acc;
      }, {});

      const recordingsList = Object.values(groupedRecordings);
      setRecordings(recordingsList);

      const details = {};
      await Promise.all(
        recordingsList.map(async (recording) => {
          const recordingDetails = await fetchRecordingDetails(recording.id);
          if (recordingDetails) {
            details[recording.id] = recordingDetails;
          }
        })
      );
      setRecordingsDetails(details);
    } catch (err) {
      setError('Wystąpił błąd: ' + err.message);
    } finally {
      setIsLoadingRecordings(false);
    }
  };

  const handleEditTag = async () => {
    if (!editedTagName.trim()) return;

    setIsProcessing(true);
    try {
      const response = await fetch('/api/tags.php', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: selectedTag.id,
          name: editedTagName
        })
      });

      if (!response.ok) throw new Error('Błąd podczas edycji tagu');

      setTags(prev => ({
        ...prev,
        [activeTab]: prev[activeTab].map(tag =>
          tag.id === selectedTag.id ? { ...tag, name: editedTagName } : tag
        )
      }));

      setSelectedTag(prev => ({ ...prev, name: editedTagName }));
    } catch (err) {
      setError(err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDeleteTag = async () => {
    setIsProcessing(true);
    try {
      const response = await fetch('/api/tags.php', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: selectedTag.id })
      });

      if (!response.ok) throw new Error('Błąd podczas usuwania tagu');

      setTags(prev => ({
        ...prev,
        [activeTab]: prev[activeTab].filter(tag => tag.id !== selectedTag.id)
      }));

      setShowDeleteConfirm(false);
      setSelectedTag(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAddTag = async (e) => {
    e.preventDefault();
    if (!newTagName.trim()) return;

    setIsProcessing(true);
    try {
      const response = await fetch('/api/tags.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: newTagName,
          type: activeTab === 'keywords' ? 'keyword' : 'person'
        })
      });

      if (!response.ok) throw new Error('Błąd podczas dodawania');

      const result = await response.json();
      setTags(prev => ({
        ...prev,
        [activeTab]: [...prev[activeTab], { id: result.id, name: newTagName, recordings_count: 0 }]
      }));

      setNewTagName('');
    } catch (err) {
      setError(err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const getFilteredTags = (type) => {
    return tags[type].filter(tag =>
      !searchQuery || tag.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const calculateFontSize = (count) => {
    if (!count) return 'fs-6';
    if (count > 16) return 'fs-1';
    if (count > 12) return 'fs-2';
    if (count > 8) return 'fs-3';
    if (count > 4) return 'fs-4';
    if (count > 2) return 'fs-5';
    return 'fs-6';
  };

  const groupTagsByFirstChar = (tags) => {
    return tags.reduce((groups, tag) => {
      const firstChar = tag.name.charAt(0).toUpperCase();
      const groupChar = /^[0-9]/.test(firstChar) ? '#' : firstChar;
      //const groupChar = /^[0-9]/.test(firstChar) ? '#\u00A0' : firstChar + '\u00A0';

      if (!groups[groupChar]) {
        groups[groupChar] = [];
      }
      groups[groupChar].push(tag);
      return groups;
    }, {});
  };

const renderTagList = (type) => {
  const filteredTags = getFilteredTags(type);
  const groupedTags = groupTagsByFirstChar(filteredTags);
  const sortedGroups = Object.keys(groupedTags).sort((a, b) => {
    if (a === '#') return -1;
    if (b === '#') return 1;
    return a.localeCompare(b);
  });

  return (<div className="row"><div className="col-lg-1"></div>
    <div className="col-lg-10 mx-auto d-flex flex-wrap gap-3 align-items-baseline">
      {sortedGroups.map((group) => (
        <React.Fragment key={group}>
          <span className="text-muted fw-bold align-self-center px-2 border-end">
            {group}
          </span>
          {groupedTags[group].map((tag) => (
            <span
              key={tag.id}
              onClick={() => handleTagClick(tag)}
              className={`${calculateFontSize(tag.recordings_count)} text-primary`}
              style={{ cursor: 'pointer' }}
            >
              {tag.name}
              {tag.recordings_count > 0 && (
                <span className="text-muted ms-1">({tag.recordings_count})</span>
              )}
            </span>
          ))}
        </React.Fragment>
      ))}
      {sortedGroups.length === 0 && (
        <div className="text-muted">
          {searchQuery ? 'Brak wyników dla podanej frazy' : 'Brak dostępnych tagów'}
        </div>
      )}
    </div><div className="col-lg-1"></div>
    </div>
  );
};

  

  if (isLoading) {
    return (
      <div className="text-center mt-4">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Ładowanie...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      <PageTitle title="Tagi" />
    <div className="p-4">
      {error && (
        <div className="alert alert-danger mb-4">
          {error}
        </div>
      )}

      <div className="d-flex gap-3 mb-4">
        <InputGroup className="flex-grow-1 me-3">
          <InputGroup.Text>
            <Search size={20} />
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Szukaj ..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </InputGroup>

        <Form onSubmit={handleAddTag} className="d-flex align-items-stretch w-50">
          <Form.Control
            type="text"
            placeholder={`Dodaj ${activeTab === 'keywords' ? 'słowo' : 'osobę'}...`}
            value={newTagName}
            onChange={(e) => setNewTagName(e.target.value)}
            className="me-2"
          />
          <Button type="submit" variant="primary" disabled={isProcessing}>
            {isProcessing ? <Spinner size="sm" /> : 'Dodaj'}
          </Button>
        </Form>
      </div>

      <Tabs
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        className="mb-4"
      >
        <Tab eventKey="keywords" title={`Słowa kluczowe (${tags.keywords.length})`}>
          {renderTagList('keywords')}
        </Tab>
        <Tab eventKey="persons" title={`Osoby (${tags.persons.length})`}>
          {renderTagList('persons')}
        </Tab>
      </Tabs>

      <Modal show={selectedTag !== null} onHide={() => setSelectedTag(null)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div className="w-auto">
                <Badge bg={selectedTag?.type === 'keyword' ? 'info' : 'success'}>
                  {selectedTag?.type === 'keyword' ? 'Słowo kluczowe' : 'Osoba'}
                </Badge>
              </div>
                <Form.Control
                  type="text"
                  value={editedTagName}
                  onChange={(e) => setEditedTagName(e.target.value)}
                  size="xl"
                  className="d-flex mx-3 gap-2 align-items-center"
                />
              <div className="d-flex gap-2">
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => setShowDeleteConfirm(true)}
                  disabled={isProcessing}
                >
                  Usuń
                </Button>
                <Button variant="primary" size="sm" onClick={handleEditTag} disabled={isProcessing}>
                  {isProcessing && <Spinner size="sm" animation="border" className="me-1" />}
                  Zapisz
                </Button>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TagRecordingsList
            recordings={recordings}
            recordingsDetails={recordingsDetails}
            isLoading={isLoadingRecordings}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Potwierdź usunięcie</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>Czy na pewno chcesz usunąć?</p>
          <p className="fw-bold mb-0">{selectedTag?.name}</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setShowDeleteConfirm(false)}
          >
            Anuluj
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={handleDeleteTag}
            disabled={isProcessing}
          >
            {isProcessing ? <Spinner size="sm" /> : 'Usuń'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    </>
  );
};

export default TagsManagement;
