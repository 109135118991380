import React from 'react';
import { Card, Form, Button, Badge } from 'react-bootstrap';
import { Search, ChevronLeft, ChevronRight } from 'lucide-react';

const TranscriptionView = ({
  transcription,
  currentTranscript,
  searchQuery,
  matches,
  currentMatchIndex,
  onSearch,
  onNextMatch,
  onPreviousMatch,
  onTranscriptClick,
  transcriptionRef,
  subtitleTags = {}
}) => {
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const highlightText = (text, query) => {
    if (!query.trim()) return text;
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === query.toLowerCase() ? (
            <mark key={index} className="bg-warning">{part}</mark>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <Card>
      <style>
        {`
          .highlight-transition {
            animation: highlight 1s ease-out;
          }

          @keyframes highlight {
            0% {
              Abackground-color: rgba(255, 235, 59, 0.5);
            }
            100% {
              background-color: inherit;
            }
          }

          .transcript-item {
            transition: background-color 0.3s ease;
          }

          .atranscript-item:hover {
            background-color: rgba(0, 123, 255, 0.1) !important;
          }

          .transcript-container::-webkit-scrollbar {
            width: 8px;
          }

          .transcript-container::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 4px;
          }

          .transcript-container::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 4px;
          }

          .transcript-container::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}
      </style>

      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">Transkrypcja</h5>
          <div className="position-relative d-flex gap-2 fs-4" style={{ width: '60%' }}>
            <div className="position-relative flex-grow-1">
              <Form.Control
                type="text"
                placeholder="Szukaj..."
                value={searchQuery}
                onChange={(e) => onSearch(e.target.value)}
                className="pe-4"
              />
              <Search
                size={16}
                className="position-absolute"
                style={{
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#6c757d'
                }}
              />
            </div>
            {matches.length > 0 && (
              <div className="d-flex gap-2 align-items-center">
                <small className="text-muted">
                  {currentMatchIndex + 1} z {matches.length}
                </small>
                <div className="d-flex gap-1">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={onPreviousMatch}
                    title="Poprzedni wynik"
                  >
                    <ChevronLeft size={16} />
                  </Button>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={onNextMatch}
                    title="Następny wynik"
                  >
                    <ChevronRight size={16} />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          ref={transcriptionRef}
          className="fs-4 transcript-container"
          style={{
            height: 'calc(100vh - 500px)',
	      minHeight: '300px', // minimalna wysokość w pikselach
            overflowY: 'auto',
            scrollBehavior: 'smooth'
          }}
        >
          {transcription.map(subtitle => (
            <div
              key={subtitle.id}
              id={`transcript-${subtitle.id}`}
              className={`transcript-item p-3 rounded mb-2 ${
                currentTranscript?.id === subtitle.id
                  ? 'bg-primary text-white'
                  : 'bg-light'
              }`}
              onClick={() => onTranscriptClick(subtitle)}
              style={{ cursor: 'pointer' }}
            >
              <div className={`small mb-1 ${
                currentTranscript?.id === subtitle.id
                  ? 'text-white-50'
                  : 'text-muted'
              }`}>
                {formatTime(subtitle.start)} - {formatTime(subtitle.end)}
              </div>
              <div>{highlightText(subtitle.text, searchQuery)}</div>
              {subtitleTags[subtitle.start]?.length > 0 && (
                <div className="mt-2 d-flex flex-wrap gap-1">
                  {subtitleTags[subtitle.start].map(tag => (
                    <Badge
                      key={tag.id}
                      bg={tag.type === 'keyword' ? 'info' : 'success'}
                      className="px-2 py-1"
                    >
                      {tag.name}
                    </Badge>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default TranscriptionView;
