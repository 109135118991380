import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import AudioPlayer from './AudioPlayer';
import TranscriptionEditor from './TranscriptionEditor';
import TagListAudio from './TagListAudio';

const EditableAudioPlayer = ({
  show,
  onHide,
  personName,
  recordingDate,
  audioUrl,
  transcriptUrl,
  audioFileName,
  transcriptFileName,
  recordingId,
  isLocked,
  userEmail,
  recording,
  initialTime = 0
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(initialTime);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [transcription, setTranscription] = useState([]);
  const [currentSubtitle, setCurrentSubtitle] = useState(null);
  const [editingSubtitle, setEditingSubtitle] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [userSelectedSubtitle, setUserSelectedSubtitle] = useState(null);

  const audioRef = useRef(null);

  useEffect(() => {
    if (show && audioRef.current) {
      audioRef.current.currentTime = initialTime;
      setCurrentTime(initialTime);
    }
  }, [show, initialTime]);

  const parseTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':');
    const [secs, ms] = seconds.split(',');
    return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(secs) + parseInt(ms) / 1000;
  };

  const parseSRT = useCallback((content) => {
    const subtitles = content.trim().split('\n\n').map(block => {
      const lines = block.split('\n');
      const [startTime, endTime] = lines[1].split(' --> ');
      return {
        id: parseInt(lines[0]),
        start: parseTime(startTime),
        end: parseTime(endTime),
        text: lines.slice(2).join('\n')
      };
    });
    return subtitles;
  }, []);

  const formatSRTTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const ms = Math.floor((seconds % 1) * 1000);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')},${ms.toString().padStart(3, '0')}`;
  };

  const generateSRT = () => {
    return transcription.map(subtitle => (
      `${subtitle.id}\n${formatSRTTime(subtitle.start)} --> ${formatSRTTime(subtitle.end)}\n${subtitle.text.trim()}`
    )).join('\n\n');
  };

  useEffect(() => {
    const loadTranscription = async () => {
      try {
        const response = await fetch(transcriptUrl, {
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          }
        });
        if (!response.ok) throw new Error('Failed to load transcription');
        const content = await response.text();
        const parsedSubtitles = parseSRT(content);
        setTranscription(parsedSubtitles);
      } catch (error) {
        console.error('Error loading transcription:', error);
      }
    };

    if (show && transcriptUrl) {
      loadTranscription();
      setHasChanges(false);
      setEditingSubtitle(null);
    }
  }, [show, transcriptUrl, parseSRT]);

  useEffect(() => {
    if (!show) {
      setIsPlaying(false);
      setCurrentTime(0);
      setCurrentSubtitle(null);
      setEditingSubtitle(null);
      setHasChanges(false);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [show]);

  useEffect(() => {
    if (userSelectedSubtitle) {
      setCurrentSubtitle(userSelectedSubtitle);
      const element = document.getElementById(`subtitle-${userSelectedSubtitle.id}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      setUserSelectedSubtitle(null);
    } else {
      const subtitle = transcription.find(
        sub => currentTime >= sub.start && currentTime < sub.end
      );

      if (subtitle?.id !== currentSubtitle?.id) {
        setCurrentSubtitle(subtitle || null);
        const element = document.getElementById(`subtitle-${subtitle?.id}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  }, [currentTime, transcription, userSelectedSubtitle, currentSubtitle]);

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
      audioRef.current.volume = volume;
      audioRef.current.playbackRate = playbackRate;
    }
  };

  const handleProgressClick = (e) => {
    const progressBar = e.currentTarget;
    const rect = progressBar.getBoundingClientRect();
    const clickPosition = (e.clientX - rect.left) / rect.width;
    const newTime = clickPosition * duration;
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleSkipAudio = (seconds) => {
    if (audioRef.current) {
      const newTime = Math.max(0, Math.min(duration, currentTime + seconds));
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleSubtitleClick = (subtitle) => {
    if (audioRef.current) {
      audioRef.current.currentTime = subtitle.start;
      setCurrentTime(subtitle.start);
      setUserSelectedSubtitle(subtitle);
    }
  };

  const handleSubtitleEdit = (subtitleId, newText) => {
    setTranscription(prev => {
      const newTranscription = prev.map(sub =>
        sub.id === subtitleId ? { ...sub, text: newText } : sub
      );
      setHasChanges(true);
      return newTranscription;
    });
  };

  const handleMergeSubtitles = (firstSubtitleId) => {
    setTranscription(prev => {
      const firstIndex = prev.findIndex(sub => sub.id === firstSubtitleId);
      const secondIndex = firstIndex + 1;

      if (firstIndex === -1 || secondIndex >= prev.length) {
        return prev;
      }

      const mergedSubtitle = {
        id: prev[firstIndex].id,
        start: prev[firstIndex].start,
        end: prev[secondIndex].end,
        text: `${prev[firstIndex].text} ${prev[secondIndex].text}`
      };

      const newTranscription = [
        ...prev.slice(0, firstIndex),
        mergedSubtitle,
        ...prev.slice(secondIndex + 1)
      ].map((sub, index) => ({
        ...sub,
        id: index + 1
      }));

      setHasChanges(true);
      return newTranscription;
    });
  };

  const handleSave = async () => {
    try {
      const srtContent = generateSRT();
      const formData = new FormData();
      formData.append('file', new Blob([srtContent], { type: 'text/plain' }), transcriptFileName);
      formData.append('chunkIndex', '0');
      formData.append('totalChunks', '1');
      formData.append('fileName', transcriptFileName);

      const response = await fetch('/api/upload.php', {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });

      if (!response.ok) throw new Error('Upload failed');
      setHasChanges(false);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  const handlePlaybackRateChange = (newRate) => {
    setPlaybackRate(newRate);
    if (audioRef.current) {
      audioRef.current.playbackRate = newRate;
    }
  };

  const handleTimeSelect = (time) => {
    if (audioRef.current) {
      audioRef.current.currentTime = time;
      setCurrentTime(time);
    }
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} size="fullscreen">
      <Modal.Header closeButton>
        <Modal.Title>
           Edytor transkrypcji
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="d-flex flex-column" style={{ height: 'calc(100vh - 76px)', minHeight: 0 }}>
<div className="d-flex gap-4 mb-3 mx-0 ms-md-5 ps-md-5">
	  {/*<div className="flex-grow-1 flex-shrink-0 w-50 mx-0 px-0 mx-md-5 px-md-5">
  </div>*/}
  <div className="d-flex align-items-center flex-grow-1 flex-shrink-0 w-50">
<span className="fs-4 rozmowca">{personName} – {recordingDate}</span> 
	  {/*<div className="fs-6 text-muted">
      <small>
        Audio: {audioFileName} –
        Transkrypcja: {transcriptFileName}
      </small>
    </div>*/}
  </div>
</div>

        <div className="d-flex flex-grow-1 gap-4 mx-0 px-0 mx-md-5 px-md-5" style={{ minHeight: 0 }}>
          <div className="flex-grow-1 d-flex flex-column" style={{ minWidth: 0 }}>
    <AudioPlayer
      audioRef={audioRef}
      audioUrl={audioUrl}
      isPlaying={isPlaying}
      currentTime={currentTime}
      duration={duration}
      volume={volume}
      playbackRate={playbackRate}
      onPlayPause={setIsPlaying}
      onTimeUpdate={handleTimeUpdate}
      onLoadedMetadata={handleLoadedMetadata}
      onVolumeChange={handleVolumeChange}
      onPlaybackRateChange={handlePlaybackRateChange}
      onProgressClick={handleProgressClick}
      onSkipAudio={handleSkipAudio}
      showTagsListButton={false}
    />
            <TranscriptionEditor
              transcription={transcription}
              currentSubtitle={currentSubtitle}
              editingSubtitle={editingSubtitle}
              hasChanges={hasChanges}
              onSubtitleClick={handleSubtitleClick}
              onEditToggle={setEditingSubtitle}
              onSubtitleEdit={handleSubtitleEdit}
              onMergeSubtitles={handleMergeSubtitles}
              onSave={handleSave}
              recordingId={recordingId}
              transcriptFile={transcriptFileName}
              isLocked={isLocked}
              userEmail={userEmail}
              recording={recording}
            />
          </div>

          <div style={{ width: '600px', minWidth: '400px' }} className="d-flex flex-column">
            <TagListAudio
              recordingId={recordingId}
              transcriptFile={transcriptFileName}
              onTimeSelect={handleTimeSelect}
	      isEditing={true}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditableAudioPlayer;
