export const aboutContent = `
  <div class="container fs-5 mx-auto px-4 py-8">
    <div class="prose max-w-none about-content">
	<p class="text-xl">Pomiechowska Biblioteka Historii Mówionej to
	innowacyjny projekt łączący tradycję historii mówionej
	z&nbsp;nowoczesnymi technologiami. Naszym celem jest
	zachowanie i&nbsp;udostępnienie osobistych historii, które
	składają się na wielowątkową opowieść o&nbsp;naszym
	społeczeństwie, kulturze i&nbsp;czasach, w&nbsp;których
	żyjemy.</p>

        <h2 class="text-2xl font-semibold ">Co robimy?</h2>
        <ul class="list-disc pl-6 ">
            <li >Nagrywamy wywiady biograficzne z&nbsp;osobami chcącymi podzielić się swoimi wspomnieniami</li>
            <li >Budujemy dostępne cyfrowe archiwum historii mówionych</li>
            <li >Tworzymy tematyczne kolekcje audio</li>
            <li >Organizujemy wydarzenia promujące historię mówioną</li>
        </ul>

        <h2 class="text-2xl font-semibold  ">Jak działamy?</h2>
	<p >Projekt opiera się na zaangażowaniu
	wolontariuszy, którzy po przeszkoleniu przeprowadzają
	i&nbsp;nagrywają wywiady. Zapewniamy profesjonalny sprzęt
	nagrywający, który wypożyczamy naszym wolontariuszom. Każde
	nagranie jest następnie opracowywane z&nbsp;wykorzystaniem
	najnowszych technologii AI, ale zawsze pod nadzorem człowieka,
	by zachować najwyższą jakość i&nbsp;wierność oryginałowi.</p>

        <h2 class="text-2xl font-semibold ">Dlaczego to robimy?</h2>
	<p >Wierzymy, że każda historia jest ważna
	i&nbsp;zasługuje na zachowanie. Historie mówione to bezcenne
	źródło wiedzy o&nbsp;życiu codziennym, przemianach społecznych
	i&nbsp;osobistych doświadczeniach. To też sposób na budowanie
	mostów między pokoleniami i&nbsp;wzmacnianie więzi
	w&nbsp;społecznościach lokalnych.</p>

        <h2 class="text-2xl font-semibold ">Jak możesz się włączyć?</h2>
        <ul class="list-disc pl-6 ">
            <li >Zostań wolontariuszem i&nbsp;nagrywaj historie (zapewniamy sprzęt i&nbsp;szkolenia)</li>
            <li >Podziel się swoją historią</li>
            <li >Zgłoś osobę, której historię warto nagrać</li>
            <li >Pomóż nam w&nbsp;opracowywaniu zebranych materiałów</li>
        </ul>

        <p class="text-xl ">Skontaktuj się z&nbsp;nami, by dowiedzieć się więcej o&nbsp;możliwościach współpracy. Razem możemy stworzyć wyjątkowe archiwum żywej historii!</p>

    </div>
</div>
`;

export const aboutPartnersContent = `
  <div class="row align-items-top">
    <div class="col-md-6">
      <h1 class="mt-4">Partnerzy</h1>
      <ul class="list-unstyled">
        <li class="mb-3"><a href="https://tworczadolina.pl" class="fs-4 text-decoration-none">Stowarzyszenie Twórcza Dolina</a></li>
        <li class="mb-3"><a href="https://historiapomiechowka.pl" class="fs-4 text-decoration-none">Historia Pomiechówka</a></li>
        <li class="mb-3"><a href="https://pomiechowek.pl" class="fs-4 text-decoration-none">Gmina Pomiechówek</a></li>
      </ul>
    </div>
    <div class="col-md-4">
      <div class="d-flex flex-column gap-4" style={{ maxHeight: '300px' }}>
        <a href="https://tworczadolina.pl" class="text-decoration-none">
          <img src="/img/tdlogo.svg" class="img-fluid w-50" style={{ maxHeight: '80px' }} alt="Twórcza Dolina Logo" />
        </a>
        <a href="https://historiapomiechowka.pl" class="text-decoration-none">
          <img src="/img/historia-pomiechowka-logo.svg" class="img-fluid w-50" style={{ maxHeight: '80px' }} alt="Historia Pomiechówka Logo" />
        </a>
        <a href="https://pomiechowek.pl" class="text-decoration-none">
          <img src="/img/gmina.svg" class="img-fluid w-50" style={{ maxHeight: '80px' }} alt="Gmina Pomiechówek Logo" />
        </a>
      </div>
    </div>
  </div>
`;
