import React, { useState, useEffect, useRef } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import VideoPlayer from './VideoPlayer';

const Beginning = ({ text = "Twój tekst tutaj" }) => {
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [showVideo, setShowVideo] = useState(false);
  const fullscreenModalRef = useRef(null);

  const handleFirstModalClose = () => setShowFirstModal(false);
  const handleFirstModalShow = () => setShowFirstModal(true);

  const handleSecondModalClose = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.error('Błąd przy wyjściu z trybu pełnoekranowego:', err);
      });
    }
    setShowSecondModal(false);
    setCountdown(10);
    setShowVideo(false);
  };

  const enterFullscreen = async () => {
    try {
      if (fullscreenModalRef.current) {
        if (fullscreenModalRef.current.requestFullscreen) {
          await fullscreenModalRef.current.requestFullscreen();
        } else if (fullscreenModalRef.current.webkitRequestFullscreen) {
          await fullscreenModalRef.current.webkitRequestFullscreen();
        } else if (fullscreenModalRef.current.msRequestFullscreen) {
          await fullscreenModalRef.current.msRequestFullscreen();
        }
      }
    } catch (err) {
      console.error('Błąd przy wchodzeniu w tryb pełnoekranowy:', err);
    }
  };

  const handleSecondModalShow = async () => {
    setShowFirstModal(false);
    setShowSecondModal(true);
    setTimeout(enterFullscreen, 100);
  };

  useEffect(() => {
    let timer;
    if (showSecondModal && countdown >= 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === -1 && !showVideo) {
      setShowVideo(true);
    }
    return () => clearTimeout(timer);
  }, [countdown, showSecondModal, showVideo]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && showSecondModal) {
        handleSecondModalClose();
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, [showSecondModal]);

  return (
    <>
      <Container
        fluid
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ minHeight: '80vh' }}
      >
	     <img
                  src="/img/pbhm-logo-3.svg"
                  alt="PBHM logo"
                  className="w-75 img-fluid"
                />
        <Button
          variant="primary"
          size="lg"
          onClick={handleFirstModalShow}
          className="px-4 py-3"
	    style={{
    fontSize: '2rem',
    fontWeight: '700',
  }}
        >
          Zaczynamy
        </Button>
      </Container>

      {/* Pierwszy Modal z grafiką */}
      <Modal
        show={showFirstModal}
        onHide={handleFirstModalClose}
        size="lg"
        centered
      >
        <Modal.Body className="p-0 position-relative">
          <img
            src="/videos/demo-bg.png"
            alt="Demo Background"
            className="w-100 h-auto"
          />
          <Button
            variant="light"
            size="lg"
            onClick={handleSecondModalShow}
            className="position-absolute top-50 start-50 translate-middle rounded-circle p-4 d-flex align-items-center justify-content-center"
            style={{
              width: '100px',
              height: '100px'
            }}
          >
            <FontAwesomeIcon
              icon={faPlay}
              className="fs-1"
            />
          </Button>
        </Modal.Body>
      </Modal>

      {/* Drugi Modal z odliczaniem i filmem */}
      <Modal
        show={showSecondModal}
        onHide={handleSecondModalClose}
        fullscreen
        contentClassName="bg-black"
      >
        <div ref={fullscreenModalRef} className="h-100 bg-black">
          <Modal.Header
            closeButton
            closeVariant="white"
            className="border-0 bg-black"
          />
          <Modal.Body className="p-0 m-0">
            {!showVideo ? (
              <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
                <div
                  className="d-flex align-items-center justify-content-center rounded-circle bg-transparent text-white"
                  style={{
                    width: '300px',
                    height: '300px',
                    border: '8px solid white',
                    fontSize: '160px',
                    fontWeight: '700',
		    fontVariantNumeric: 'normal',
                    lineHeight: 1
                  }}
                >
                  {countdown}
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
                <VideoPlayer
                  videoSrc="/videos/pp-new.mp4"
                  posterSrc=""
                  maxWidth="90vw"
                  aspectRatio="16:9"
                  head=" "
		    background="bg-black"
                  autoplay={true}
                  title=" "
                />
              </div>
            )}
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default Beginning;
