import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, Alert, Button, Image } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, Edit, Lock, Unlock } from 'lucide-react';
import EditableAudioPlayer from './EditableAudioPlayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import ProofreadingStatus from './ProofreadingStatus';
import MediaViewer from './MediaViewer';

const RecordingView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [recording, setRecording] = useState({
    metadata: {},
    proofreading: { email: '', status: '' },
    description: {},
    technical: { storage: [], transcripts: [] }
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPlayer, setShowPlayer] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [userEmail, setUserEmail] = useState('');

  const STORAGE_BASE_URL = '/storage/recordings';

  const getFileUrl = useCallback((fileName) => {
    return `${STORAGE_BASE_URL}/${id}/${fileName}`;
  }, [id]);

  const getTranscriptUrl = useCallback((fileName) => {
    return `/api/getsrt.php?id=${id}&filename=${fileName}`;
  }, [id]);

  const fetchUserEmail = async () => {
    try {
      const response = await fetch('/auth-api/check-session', {
        credentials: 'include'
      });
      const data = await response.json();
      if (data.email) {
        setUserEmail(data.email);
      }
    } catch (err) {
      console.error('Error fetching user email:', err);
    }
  };

  useEffect(() => {
    fetchUserEmail();
  }, []);

  useEffect(() => {
    // Handle navigation state as soon as recording data is loaded and we're not loading
    if (!loading && location.state?.showPlayer && recording.technical.storage) {
      const timestamp = location.state.initialTimestamp;
      const audioFile = location.state.audioFile;
      const transcriptFile = location.state.transcriptFile;

      if (audioFile && transcriptFile) {
        // Convert timestamp string (HH:MM:SS) to seconds
        const [hours, minutes, seconds] = timestamp.split(':').map(Number);
        const timeInSeconds = hours * 3600 + minutes * 60 + seconds;

        const selectedAudioData = {
          audioUrl: getFileUrl(audioFile),
          transcriptUrl: getTranscriptUrl(transcriptFile),
          audioFileName: audioFile,
          transcriptFileName: transcriptFile,
          initialTime: timeInSeconds
        };
        setSelectedAudio(selectedAudioData);
        setShowPlayer(true);
      }
    }
  }, [loading, location.state, recording, getFileUrl, getTranscriptUrl]);

  const fetchRecording = useCallback(async () => {
    try {
      const response = await fetch(`/api/recordings/${id}`, {
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to fetch recording');
      const result = await response.json();
      setRecording(prevState => ({
        ...prevState,
        ...result.data,
        proofreading: result.data.proofreading || { email: '', status: '' }
      }));
      setError(null);
    } catch (err) {
      setError('Error fetching recording: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchRecording();
  }, [fetchRecording]);

  const toggleLock = async () => {
    try {
      // Sprawdź czy użytkownik może odblokować
      if (recording.proofreading.email && recording.proofreading.email !== userEmail) {
        setError('Tylko osoba która zablokowała może odblokować edycję');
        return;
      }

      const updatedRecording = {
        ...recording,
        proofreading: {
          ...recording.proofreading,
          email: recording.proofreading.email ? '' : userEmail
        }
      };

      const response = await fetch(`/api/recordings/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(updatedRecording)
      });

      if (!response.ok) throw new Error('Failed to update recording');
      setRecording(updatedRecording);
    } catch (err) {
      setError('Error updating recording: ' + err.message);
    }
  };

  const handleStatusChange = async (newStatus) => {
    try {
      const updatedRecording = {
        ...recording,
        proofreading: {
          ...recording.proofreading,
          status: newStatus
        }
      };

      const response = await fetch(`/api/recordings/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(updatedRecording)
      });

      if (!response.ok) throw new Error('Failed to update recording');
      setRecording(updatedRecording);
    } catch (err) {
      setError('Error updating recording status: ' + err.message);
    }
  };

  const handlePlayAudio = useCallback((audioFile, transcriptFile, startTime = 0) => {
    const selectedAudioData = {
      audioUrl: getFileUrl(audioFile),
      transcriptUrl: getTranscriptUrl(transcriptFile),
      audioFileName: audioFile,
      transcriptFileName: transcriptFile,
      initialTime: startTime
    };
    setSelectedAudio(selectedAudioData);
    setShowPlayer(true);
  }, [getFileUrl, getTranscriptUrl]);

  if (loading) return <div>Loading...</div>;
  if (error) return <Alert variant="danger">{error}</Alert>;
  if (!recording) return <Alert variant="warning">Nie znaleziono nagrania</Alert>;

  const audioFiles = recording.technical.storage.filter(file =>
    file.match(/\.(mp3|wav)$/i)
  );
  const transcriptFiles = recording.technical.transcripts || [];
  const formatDate = (dateString) => dateString ? new Date(dateString).toLocaleDateString('pl-PL') : '';
  const hasPhoto = recording.metadata.interviewee?.photo?.length > 0;
  const isLocked = Boolean(recording.proofreading?.email);

  const recordings = location.state?.recordings || [];
  const currentIndex = recordings.findIndex(r => r.metadata.signature === id);

  const handleNext = () => {
    if (currentIndex < recordings.length - 1) {
      navigate(`/recordings/${recordings[currentIndex + 1].metadata.signature}/view`, {
        state: { recordings }
      });
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      navigate(`/recordings/${recordings[currentIndex - 1].metadata.signature}/view`, {
        state: { recordings }
      });
    } 
  };

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-start mb-4">
        <h2>Szczegóły rozmowy</h2>
        <div className="d-flex flex-column gap-2 align-items-end">
          <div className="d-flex gap-2">
            <Button
              variant="outline-primary"
              onClick={() => navigate(`/recordings/${id}/edit`)}
              className="d-flex gap-2 align-items-center"
            >
              <Edit size={16} /> Edytuj
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => navigate('/recordings')}
              className="d-flex gap-2 align-items-center"
            >
              <ArrowLeft size={16} /> Lista rozmów
            </Button>
	      {recordings.length > 0 && (<>
	  <Button
          variant="secondary"
          onClick={handlePrevious}
          disabled={currentIndex === 0}
        >
          Poprzednia
        </Button>
        <Button
          variant="primary"
          onClick={handleNext}
          disabled={currentIndex === recordings.length - 1}
        >
          Następna
        </Button> </> )}
          </div>

          <div className="d-flex gap-2 align-items-center">
            <Button
              variant={isLocked ? "danger" : "success"}
              onClick={toggleLock}
              className="d-flex gap-2 align-items-center"
              disabled={isLocked && recording.proofreading.email !== userEmail}
            >
              {isLocked ? <Lock size={16} /> : <Unlock size={16} />}
              {isLocked ? 'Odblokuj transkrypcję' : 'Zablokuj transkrypcję'}
            </Button>

            {isLocked && (
              <small className="p-2 rounded d-flex align-items-center bg-light border">
                {userEmail === recording.proofreading.email
                  ? "Transkrypcja zablokowana przez ciebie"
                  : `Transkrypcja zablokowana przez: ${recording.proofreading.email}`
                }
              </small>
            )}
          </div>

          <ProofreadingStatus
            status={recording.proofreading?.status || ''}
            onChange={handleStatusChange}
            disabled={!isLocked || recording.proofreading.email !== userEmail}
          />
        </div>
      </div>

      <Row>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Header>
              <h4 className="mb-0">Rozmówca</h4>
            </Card.Header>
            <Card.Body>
              <dl className="row mb-0">
                <dd className="col-sm-8 rozmowca fs-4">{recording.metadata.interviewee?.name || '-'}</dd>
                <dt className="col-12 mt-3">Biogram rozmówcy</dt>
                <dd className="col-12">
                  {recording.metadata.interviewee?.biography || '-'}
                </dd>
              </dl>
              {hasPhoto && (
                <Image
                  src={getFileUrl(recording.metadata.interviewee.photo)}
                  alt={`Zdjęcie: ${recording.metadata.interviewee.name}`}
                  fluid
                  className="mb-3 rounded-circle w-48 h-48 object-cover shadow"
                  style={{ maxHeight: '300px', objectFit: 'contain' }}
                />
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="mb-4">
            <Card.Header>
              <h4 className="mb-0">Informacje</h4>
            </Card.Header>
            <Card.Body>
              <dl className="row mb-0">
                <dt className="col-sm-4">Sygnatura</dt>
                <dd className="col-sm-8">{recording.metadata.signature}</dd>

                <dt className="col-sm-4">Tytuł</dt>
                <dd className="col-sm-8">{recording.metadata.title}</dd>

                <dt className="col-sm-4">Prowadzący wywiad</dt>
                <dd className="col-sm-8">{recording.metadata.interviewer || '-'}</dd>

                <dt className="col-sm-4">Data</dt>
                <dd className="col-sm-8">{formatDate(recording.metadata.date)}</dd>

                <dt className="col-sm-4">Lokalizacja</dt>
                <dd className="col-sm-8">{recording.metadata.location || '-'}</dd>

                <dt className="col-sm-4">Czas trwania</dt>
                <dd className="col-sm-8">{recording.metadata.duration || '-'}</dd>
              </dl>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="mb-4">
            <Card.Header>
              <h4 className="mb-0">Nagrania rozmowy</h4>
            </Card.Header>
            <Card.Body>
              {audioFiles.length > 0 ? (
                <ul className="list-group">
                  {audioFiles.map((audioFile, index) => {
                    const transcript = transcriptFiles.find(t => t.audioFile === audioFile);
                    return (
                      <li key={audioFile} className="list-group-item">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <div>Odtwórz {audioFiles.length > 1 ? index + 1 : ''} / transkrypcja</div>
                          </div>
                          {transcript && (
                            <Button
                              variant="primary"
                              className="rounded-circle d-flex align-items-center justify-content-center p-3"
                              onClick={() => handlePlayAudio(audioFile, transcript.srtFile)}
                              style={{ width: '48px', height: '48px' }}
                            >
                              <FontAwesomeIcon icon={faPlay} />
                            </Button>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className="text-muted mb-0">Brak plików audio</p>
              )}
            </Card.Body>
          </Card>

          {recording.description.summary && (
            <Card className="mb-4">
              <Card.Header>
                <h4 className="mb-0">Streszczenie</h4>
              </Card.Header>
              <Card.Body>
                <p className="mb-0">{recording.description.summary}</p>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      {selectedAudio && (
        <EditableAudioPlayer
          show={showPlayer}
          onHide={() => {
            setShowPlayer(false);
            // Clear navigation state
            navigate(location.pathname, { replace: true });
          }}
          audioUrl={selectedAudio.audioUrl}
          personName={recording.metadata.interviewee?.name}
          recordingDate={formatDate(recording.metadata.date)}
          transcriptUrl={selectedAudio.transcriptUrl}
          audioFileName={selectedAudio.audioFileName}
          transcriptFileName={selectedAudio.transcriptFileName}
          recordingId={recording.metadata.signature}
          isLocked={Boolean(recording.proofreading?.email)}
          userEmail={userEmail}
          recording={recording}
          initialTime={selectedAudio.initialTime}
        />
      )}
      <MediaViewer recordingId={id} />
    </Container>
  );
};

export default RecordingView;
