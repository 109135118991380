export const privacyContent = `
  <div class="container fs-5 mx-auto px-4 py-8">
    <div class="prose max-w-none about-content">
      <h2 class="text-2xl font-semibold">1. Informacje ogólne</h2>
      <p>Niniejsza Polityka Prywatności określa zasady przetwarzania i&nbsp;ochrony danych osobowych użytkowników Pomiechowskiej Biblioteki Historii Mówionej.</p>

      <h2 class="text-2xl font-semibold">2. Administrator danych</h2>
      <p>Administratorem danych jest Pomiechowska Biblioteka Historii Mówionej z&nbsp;siedzibą w&nbsp;Pomiechówku.</p>

      <h2 class="text-2xl font-semibold">3. Cel przetwarzania danych</h2>
      <p>Dane osobowe przetwarzane są w&nbsp;celu:</p>
      <ul class="list-disc pl-6">
        <li>Realizacji projektu historii mówionej</li>
        <li>Archiwizacji materiałów historycznych</li>
        <li>Kontaktu z&nbsp;uczestnikami projektu</li>
      </ul>

      <h2 class="text-2xl font-semibold">4. Pliki Cookie</h2>
      <p>Nasza strona wykorzystuje pliki cookie, które są małymi plikami tekstowymi zapisywanymi na urządzeniu użytkownika. Służą one do:</p>
      <ul class="list-disc pl-6">
        <li>Zbierania statystyk odwiedzin w celu poprawy jakości strony</li>
        <li>Zapewnienia podstawowych funkcjonalności strony, takich jak utrzymanie sesji logowania</li>
      </ul>

      <p>Użytkownik może w każdej chwili zmienić ustawienia przeglądarki, aby nie akceptowała plików cookie lub informowała o ich przesyłaniu. Należy jednak pamiętać, że nieakceptowanie plików cookie może spowodować utrudnienia w korzystaniu ze strony.</p>

      <h2 class="text-2xl font-semibold">5. Prawa użytkowników</h2>
      <p>Każdy użytkownik ma prawo do:</p>
      <ul class="list-disc pl-6">
        <li>Dostępu do swoich danych</li>
        <li>Sprostowania danych</li>
        <li>Usunięcia danych</li>
        <li>Ograniczenia przetwarzania</li>
      </ul>

      <h2 class="text-2xl font-semibold">6. Zarządzanie plikami cookie</h2>
      <p>Szczegółowe informacje o tym, jak zarządzać plikami cookie w najpopularniejszych przeglądarkach:</p>
      <ul class="list-disc pl-6">
        <li>Google Chrome: Ustawienia → Prywatność i bezpieczeństwo → Pliki cookie i inne dane witryn</li>
        <li>Mozilla Firefox: Opcje → Prywatność i bezpieczeństwo → Ciasteczka i dane witryn</li>
        <li>Microsoft Edge: Ustawienia → Pliki cookie i uprawnienia witryn</li>
        <li>Safari: Preferencje → Prywatność</li>
      </ul>
    </div>
  </div>
`;

export const contactContent = `
  <div class="container fs-5 mx-auto px-4 py-8">
    <div class="prose max-w-none">
      <h2 class="text-2xl font-semibold">Dane kontaktowe</h2>
      <p>
        <strong>Adres:</strong><br />
        Biblioteka Publiczna Gminy Pomiechówek<br />
        ul. Jana Kilińskiego 3<br />
        05-180 Pomiechówek
      </p>
      <p>
        <strong>Telefon:</strong> +48 22 785-46-97<br />
        <strong>Email:</strong> biblioteka@pomiechowek.pl
      </p>
              <img src="/img/biblioteka-logo-kolor.png" class="w-50 img-fluid" alt="Biblioteka Logo" />
    </div>
  </div>
`;

export const footerContent = {
  address: {
    name: "Biblioteka Publiczna Gminy Pomiechówek",
    street: "ul. Jana Kilińskiego 3",
    city: "05-180 Pomiechówek",
    phone: "+48 22 785-46-97"
  },
  navigation: [
    { path: "/", label: "Start" },
    { path: "/about", label: "O projekcie" },
    { path: "/contact", label: "Kontakt" },
    { path: "/terms", label: "Regulamin" },
    { path: "/privacy", label: "Polityka prywatności" },
    { path: "/wcag", label: "Deklaracja dostępności" }
  ],
  libsocial: [
    { url: "https://www.facebook.com/Biblioteka.Publiczna.Gminy.Pomiechowek", label: "Facebook" },
    { url: "https://www.instagram.com/biblioteka.pomiechowek/", label: "Instagram" }
  ],
  social: [
    { url: "https://facebook.com", label: "Facebook" },
    { url: "https://instagram.com", label: "Instagram" }
  ]
};
