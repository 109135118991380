import React, { useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed-bottom">
      <Alert
        className="mb-0 py-3"
        variant="dark"
        style={{
          borderRadius: 0,
          backgroundColor: 'rgba(33, 37, 41, 0.95)'
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-9 mb-2 mb-md-0">
              <p className="mb-0 text-light">
                Ta strona używa plików cookie do zbierania statystyk odwiedzin i zapewnienia poprawnego jej działania.
                Korzystając z naszej strony, zgadzasz się na używanie plików cookie zgodnie z naszą{' '}
	        <a href="/privacy" className="text-white text-decoration-underline">
                  polityką prywatności
                </a>.
              </p>
            </div>
            <div className="col-12 col-md-3 text-md-end">
              <Button
                variant="primary"
                onClick={handleAccept}
                className="w-100 w-md-auto"
              >
                Akceptuję
              </Button>
            </div>
          </div>
        </div>
      </Alert>
    </div>
  );
};

export default CookieConsent;
