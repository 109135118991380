import React, { useState, useEffect, useCallback } from 'react';
import { Card, Form, Button, Alert, Modal, ProgressBar } from 'react-bootstrap';
import { AlertCircle } from 'lucide-react';
import MediaEditModal from './MediaEditModal';
import MediaThumbnail from './MediaThumbnail';
import MediaLightboxModal from './MediaLightboxModal';

const MediaUploader = ({ recordingId, type, onUploadComplete }) => {
  const [files, setFiles] = useState([]);
  const [uploads, setUploads] = useState([]);
  const [error, setError] = useState(null);
  const [lightbox, setLightbox] = useState({ show: false, item: null });
  const [deleteConfirm, setDeleteConfirm] = useState({ show: false, file: null });
  const [editModal, setEditModal] = useState({ show: false, file: null });

  const validateFile = (file) => {
    const maxSize = 10 * 1024 * 1024; // 10MB
    const allowedTypes = {
      photos: ['image/jpeg', 'image/png', 'image/gif'],
      documents: ['application/pdf', 'image/jpeg', 'image/png', 'image/gif']
    };

    if (file.size > maxSize) {
      throw new Error(`${file.name}: Plik jest zbyt duży. Maksymalny rozmiar to 10MB.`);
    }

    if (!allowedTypes[type].includes(file.type)) {
      throw new Error(`${file.name}: Niedozwolony typ pliku. Dozwolone są: ${
        type === 'photos' ? 'JPG, PNG, GIF' : 'PDF, JPG, PNG, GIF'
      }`);
    }
  };

  const fetchFiles = useCallback(async () => {
    try {
      const response = await fetch(
        `/api/media.php?recordingId=${recordingId}&type=${type}&action=metadata`,
        { credentials: 'include' }
      );
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Błąd pobierania plików');
      }
      const result = await response.json();
      setFiles(result.data);
    } catch (err) {
      setError(err.message);
    }
  }, [recordingId, type]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const uploadFile = async (file) => {
    const upload = {
      id: Math.random().toString(36).substr(2, 9),
      file,
      progress: 0,
      status: 'pending'
    };

    setUploads(prev => [...prev, upload]);

    try {
      validateFile(file);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('title', file.name);
      formData.append('description', '');

      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploads(prev => prev.map(u =>
            u.id === upload.id ? { ...u, progress } : u
          ));
        }
      };

      await new Promise((resolve, reject) => {
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr.response);
          } else {
            reject(new Error(xhr.statusText));
          }
        };
        xhr.onerror = () => reject(new Error('Błąd sieci'));

        xhr.open('POST', `/api/media.php?recordingId=${recordingId}&type=${type}`);
        xhr.withCredentials = true;
        xhr.send(formData);
      });

      setUploads(prev => prev.map(u =>
        u.id === upload.id ? { ...u, status: 'completed' } : u
      ));

      setTimeout(() => {
        setUploads(prev => prev.filter(u => u.id !== upload.id));
      }, 2000);

      await fetchFiles();
      if (onUploadComplete) onUploadComplete();

    } catch (err) {
      setUploads(prev => prev.map(u =>
        u.id === upload.id ? { ...u, status: 'error', error: err.message } : u
      ));

      setTimeout(() => {
        setUploads(prev => prev.filter(u => u.id !== upload.id));
      }, 5000);
    }
  };

  const handleFilesSelect = (e) => {
    const selectedFiles = Array.from(e.target.files || []);
    setError(null);
    selectedFiles.forEach(file => {
      uploadFile(file);
    });
    e.target.value = '';
  };

  const handleDelete = async (filename) => {
    try {
      const response = await fetch(
        `/api/media.php?recordingId=${recordingId}&type=${type}&filename=${filename}`,
        {
          method: 'DELETE',
          credentials: 'include'
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Błąd podczas usuwania pliku');
      }

      await fetchFiles();
      setDeleteConfirm({ show: false, file: null });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEdit = async (formData) => {
    try {
      const response = await fetch(
        `/api/media.php?recordingId=${recordingId}&type=${type}&filename=${editModal.file.filename}&action=update`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
          credentials: 'include'
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Błąd podczas aktualizacji pliku');
      }

      await fetchFiles();
      setEditModal({ show: false, file: null });
    } catch (err) {
      setError(err.message);
    }
  };

  const getFileUrl = (file, isThumbnail = false) => {
    const action = isThumbnail ? 'thumbnail' : 'file';
    return `/api/media.php?recordingId=${recordingId}&type=${type}&action=${action}&filename=${file.filename}`;
  };

  return (
    <>
      <Card className="h-100">
        <Card.Header className="bg-light">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">
              {type === 'photos' ? 'Zdjęcia' : 'Dokumenty'}
            </h5>
            <small className="text-muted">
              {files.length} {type === 'photos' ? 'zdjęć' : 'dokumentów'}
            </small>
          </div>
        </Card.Header>
        <Card.Body>
          {error && (
            <Alert variant="danger" className="d-flex align-items-center gap-2">
              <AlertCircle size={20} />
              {error}
            </Alert>
          )}

          <div className="upload-area mb-3">
            <Form.Group>
              <Form.Label>Wybierz pliki</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type="file"
                  multiple
                  accept={type === 'photos' ?
                    '.jpg,.jpeg,.png,.gif' :
                    '.jpg,.jpeg,.png,.gif,.pdf'
                  }
                  onChange={handleFilesSelect}
                  className="mb-2"
                />
              </div>
              <Form.Text className="text-muted">
                Dozwolone formaty: {type === 'photos' ?
                  'JPG, PNG, GIF' :
                  'PDF, JPG, PNG, GIF'
                } (max. 10MB)
              </Form.Text>
            </Form.Group>
          </div>

          {uploads.length > 0 && (
            <div className="mb-3">
              {uploads.map(upload => (
                <div key={upload.id} className="mb-2">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <small className="text-truncate" style={{ maxWidth: '70%' }}>
                      {upload.file.name}
                    </small>
                    <small className={`text-${upload.status === 'error' ? 'danger' :
                                            upload.status === 'completed' ? 'success' :
                                            'primary'}`}>
                      {upload.status === 'error' ? 'Błąd' :
                       upload.status === 'completed' ? 'Zakończono' :
                       `${upload.progress}%`}
                    </small>
                  </div>
                  <ProgressBar
                    now={upload.progress}
                    variant={upload.status === 'error' ? 'danger' :
                            upload.status === 'completed' ? 'success' :
                            'primary'}
                    className="mb-1"
                  />
                  {upload.status === 'error' && (
                    <small className="text-danger d-block">{upload.error}</small>
                  )}
                </div>
              ))}
            </div>
          )}

          <hr />

          <div className="d-flex flex-wrap gap-3">
            {files.map((file, index) => (
              <MediaThumbnail
                key={index}
                file={file}
                getFileUrl={getFileUrl}
                onEdit={() => setEditModal({ show: true, file })}
                onDelete={() => setDeleteConfirm({ show: true, file })}
                onPreview={(file) => setLightbox({ show: true, item: file })}
              />
            ))}
            {files.length === 0 && (
              <div className="text-muted w-100 text-center py-3">
                Brak przesłanych {type === 'photos' ? 'zdjęć' : 'dokumentów'}
              </div>
            )}
          </div>
        </Card.Body>
      </Card>

      {/* Delete Confirmation Modal */}
      <Modal
        show={deleteConfirm.show}
        onHide={() => setDeleteConfirm({ show: false, file: null })}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Potwierdź usunięcie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Czy na pewno chcesz usunąć plik "{deleteConfirm.file?.title || deleteConfirm.file?.filename}"?</p>
          <p className="text-danger mb-0">
            <AlertCircle size={20} className="me-2" />
            Tej operacji nie można cofnąć.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteConfirm({ show: false, file: null })}>
            Anuluj
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteConfirm.file && handleDelete(deleteConfirm.file.filename)}
          >
            Usuń
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Modal */}
      <MediaEditModal
        show={editModal.show}
        file={editModal.file}
        onHide={() => setEditModal({ show: false, file: null })}
        onSubmit={handleEdit}
      />

      {/* Lightbox Modal */}
      <MediaLightboxModal
        show={lightbox.show}
        item={lightbox.item}
        onHide={() => setLightbox({ show: false, item: null })}
        getFileUrl={getFileUrl}
      />
    </>
  );
};

export default MediaUploader;
