import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';
import Navigation from './components/Navigation';
import Register from './components/Register';
import Login from './components/Login';
import Public from './components/Public';
import PasswordReset from './components/PasswordReset';
import RecordingsList from './components/RecordingsList';
import RecordingForm from './components/RecordingForm';
import RecordingView from './components/RecordingView';
import TagsManagement from './components/TagsManagement';
import ListeningModule from './components/ListeningModule';
import RecordingPlayer from './components/RecordingPlayer';
import GoogleAnalytics from './components/GoogleAnalytics';
import CookieConsent from './components/CookieConsent';
import JoinProject from './components/JoinProject';
import Beginning from './components/Beginning';

import { Footer, About, Contact, Privacy, Terms, Wcag } from './components/StaticPages';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/my.css';
import './styles/prod-or-dev.css';

// Komponent App Router do obsługi sprawdzania wersji
function AppRouter({ isAuthenticated, userRole, updateAuthState, handleLogout }) {
  const location = useLocation();

  useEffect(() => {
    const checkVersion = async () => {
      if (location.pathname === '/') {
        try {
          const response = await fetch('/version.json?' + Date.now());
          const { version } = await response.json();
          const lastVersion = localStorage.getItem('appVersion');

          if (lastVersion && lastVersion !== version) {
            window.location.reload();
            console.log('Version change');
            console.log(lastVersion);
            console.log(version);
          }
          localStorage.setItem('appVersion', version);
        } catch (error) {
          console.error('Version check failed:', error);
        }
      }
    };

    checkVersion();
  }, [location.pathname]);

  return (
    <>
      <Navigation
        isAuthenticated={isAuthenticated}
        userRole={userRole}
        onLogout={handleLogout}
      />
      <Container className="mt-4">
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<Public />} />
          <Route path="/beginning" element={<Beginning />} />
          <Route path="/about" element={<About />} />
          <Route path="/wcag" element={<Wcag />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/join" element={<JoinProject />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route
            path="/login"
            element={
              <Login
                setIsAuthenticated={(isAuth) => updateAuthState(isAuth, isAuth ? userRole : null)}
                setUserRole={(role) => updateAuthState(true, role)}
              />
            }
          />
          <Route
            path="/verify-login"
            element={
              <Login
                setIsAuthenticated={(isAuth) => updateAuthState(isAuth, isAuth ? userRole : null)}
                setUserRole={(role) => updateAuthState(true, role)}
                mode="verify-link"
              />
            }
          />

          {/* Authenticated user routes */}
          <Route
            path="/library"
            element={
              isAuthenticated ?
              <ListeningModule /> :
              <Navigate to="/" />
            }
          />

          <Route
            path="/library/recording/:id"
            element={
              isAuthenticated ?
              <RecordingPlayer /> :
              <Navigate to="/" />
            }
          />

          {/* Editor/Admin routes */}
          <Route
            path="/recordings"
            element={
              isAuthenticated && (userRole === 'editor' || userRole === 'admin') ?
              <RecordingsList userRole={userRole} /> :
              <Navigate to="/" />
            }
          />
          <Route
            path="/recordings/new"
            element={
              isAuthenticated && (userRole === 'editor' || userRole === 'admin') ?
              <RecordingForm mode="create" userRole={userRole} /> :
              <Navigate to="/" />
            }
          />
          <Route
            path="/recordings/:id/view"
            element={
              isAuthenticated && (userRole === 'editor' || userRole === 'admin') ?
              <RecordingView /> :
              <Navigate to="/" />
            }
          />
          <Route
            path="/recordings/:id/edit"
            element={
              isAuthenticated && (userRole === 'editor' || userRole === 'admin') ?
              <RecordingForm mode="edit" userRole={userRole} /> :
              <Navigate to="/" />
            }
          />
          <Route
            path="/tags"
            element={
              isAuthenticated && (userRole === 'editor' || userRole === 'admin') ?
              <TagsManagement /> :
              <Navigate to="/" />
            }
          />
        </Routes>
      </Container>
    </>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleLogout = async () => {
    try {
      const response = await fetch('/auth-api/logout', {
        method: 'POST',
        credentials: 'include'
      });

      if (response.ok) {
        setIsAuthenticated(false);
        setUserRole(null);
        sessionStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('userRole');
        return true;
      }
      return false;
    } catch (err) {
      console.error('Logout failed:', err);
      return false;
    }
  };

  const updateAuthState = (isAuth, role) => {
    setIsAuthenticated(isAuth);
    setUserRole(role);

    if (isAuth && role) {
      sessionStorage.setItem('isAuthenticated', 'true');
      sessionStorage.setItem('userRole', role);
    } else {
      sessionStorage.removeItem('isAuthenticated');
      sessionStorage.removeItem('userRole');
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const storedIsAuth = sessionStorage.getItem('isAuthenticated') === 'true';
        const storedRole = sessionStorage.getItem('userRole');

        if (storedIsAuth && storedRole) {
          setIsAuthenticated(true);
          setUserRole(storedRole);
        }

        const response = await fetch('/auth-api/check-session', {
          credentials: 'include'
        });

        const data = await response.json();

        if (response.ok && data.authenticated) {
          updateAuthState(true, data.role);
        } else {
          updateAuthState(false, null);
        }
      } catch (err) {
        console.error('Session check failed:', err);
        const storedIsAuth = sessionStorage.getItem('isAuthenticated') === 'true';
        const storedRole = sessionStorage.getItem('userRole');
        if (storedIsAuth && storedRole) {
          setIsAuthenticated(true);
          setUserRole(storedRole);
        }
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();

    const interval = setInterval(checkAuth, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <GoogleAnalytics />
      <AppRouter
        isAuthenticated={isAuthenticated}
        userRole={userRole}
        updateAuthState={updateAuthState}
        handleLogout={handleLogout}
      />
      <Footer />
      <CookieConsent />
    </BrowserRouter>
  );
}

export default App;
