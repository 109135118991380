import React from 'react';
import { Button } from 'react-bootstrap';
import { X, FileText, Edit2 } from 'lucide-react';

const MediaThumbnail = ({ 
  file, 
  getFileUrl, 
  onEdit, 
  onDelete, 
  onPreview 
}) => {
  const renderThumbnail = () => {
    if (file.isImage) {
      return (
        <div className="media-thumbnail">
          <img
            src={getFileUrl(file, true)}
            alt={file.title}
            className="rounded shadow-sm"
            style={{ width: '120px', height: '120px', objectFit: 'cover', cursor: 'pointer' }}
            onClick={() => onPreview(file)}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/placeholder-image.jpg';
            }}
          />
        </div>
      );
    } else if (file.isPDF) {
      return (
        <div
          className="media-thumbnail d-flex align-items-center justify-content-center bg-light rounded shadow-sm"
          style={{ width: '120px', height: '120px', cursor: 'pointer' }}
          onClick={() => window.open(getFileUrl(file), '_blank')}
        >
          <FileText size={48} className="text-primary" />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="position-relative media-item">
      {renderThumbnail()}
      <div className="position-absolute top-0 end-0 m-1 d-flex gap-1">
        <Button
          variant="light"
          size="sm"
          className="rounded-circle p-1 d-flex align-items-center justify-content-center"
          onClick={() => onEdit(file)}
          style={{ width: '24px', height: '24px' }}
        >
          <Edit2 size={16} />
        </Button>
        <Button
          variant="danger"
          size="sm"
          className="rounded-circle p-1 d-flex align-items-center justify-content-center"
          onClick={() => onDelete(file)}
          style={{ width: '24px', height: '24px' }}
        >
          <X size={16} />
        </Button>
      </div>
      <div className="mt-1 text-center">
        <small
          className="d-block text-truncate"
          style={{ maxWidth: '120px' }}
          title={file.title || file.filename}
        >
          {file.title || file.filename}
        </small>
      </div>
    </div>
  );
};

export default MediaThumbnail;
