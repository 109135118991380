import React, { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, Modal, Alert, Button, Spinner } from 'react-bootstrap';
import { FileText, AlertCircle, Download, ExternalLink, ChevronLeft, ChevronRight } from 'lucide-react';

const MediaViewer = ({ recordingId }) => {
  const [media, setMedia] = useState({
    photos: [],
    documents: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lightbox, setLightbox] = useState({
    show: false,
    item: null,
    type: null,
    currentIndex: -1,
    items: []
  });

  const getFileUrl = useCallback((file, type, isThumbnail = false) => {
    const action = isThumbnail ? 'thumbnail' : 'file';
    return `/api/media.php?recordingId=${recordingId}&type=${type}&action=${action}&filename=${file.filename}`;
  }, [recordingId]);

  const fetchMedia = useCallback(async (type) => {
    try {
      const response = await fetch(
        `/api/media.php?recordingId=${recordingId}&type=${type}&action=metadata`,
        { credentials: 'include' }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || `Błąd podczas pobierania ${type === 'photos' ? 'zdjęć' : 'dokumentów'}`);
      }

      const result = await response.json();
      return result.data || [];
    } catch (err) {
      console.error(`Error fetching ${type}:`, err);
      throw err;
    }
  }, [recordingId]);

  const navigateMedia = useCallback((direction) => {
    if (!lightbox.show || lightbox.items.length <= 1) return;

    const currentIndex = lightbox.currentIndex;
    let newIndex;

    if (direction === 'next') {
      newIndex = currentIndex + 1 >= lightbox.items.length ? 0 : currentIndex + 1;
    } else {
      newIndex = currentIndex - 1 < 0 ? lightbox.items.length - 1 : currentIndex - 1;
    }

    const newItem = lightbox.items[newIndex];
    setLightbox(prev => ({
      ...prev,
      currentIndex: newIndex,
      item: {
        ...newItem,
        src: getFileUrl(newItem, prev.type)
      }
    }));
  }, [lightbox, getFileUrl]);

  const handleKeyDown = useCallback((e) => {
    if (!lightbox.show) return;

    if (e.key === 'ArrowLeft') {
      navigateMedia('prev');
    } else if (e.key === 'ArrowRight') {
      navigateMedia('next');
    } else if (e.key === 'Escape') {
      setLightbox(prev => ({ ...prev, show: false }));
    }
  }, [lightbox.show, navigateMedia]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    const loadMedia = async () => {
      setLoading(true);
      setError(null);
      try {
        const [photos, documents] = await Promise.all([
          fetchMedia('photos'),
          fetchMedia('documents')
        ]);
        setMedia({ photos, documents });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (recordingId) {
      loadMedia();
    }
  }, [recordingId, fetchMedia]);

  const downloadFile = async (file, type) => {
    try {
      const response = await fetch(getFileUrl(file, type), {
        credentials: 'include'
      });

      if (!response.ok) throw new Error('Błąd podczas pobierania pliku');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      setError('Nie udało się pobrać pliku: ' + err.message);
    }
  };

  const openLightbox = useCallback((file, type) => {
    const items = type === 'photos' ? media.photos : media.documents;
    const currentIndex = items.findIndex(item => item.filename === file.filename);

    setLightbox({
      show: true,
      item: {
        ...file,
        src: getFileUrl(file, type)
      },
      type,
      currentIndex,
      items
    });
  }, [media, getFileUrl]);

  const renderThumbnail = (item, type) => {
    if (item.isImage) {
      return (
        <div className="position-relative media-thumbnail">
          <img
            src={getFileUrl(item, type, true)}
            alt={item.title || item.filename}
            className="rounded shadow-sm"
            style={{ cursor: 'pointer', width: '120px', height: '120px', objectFit: 'cover' }}
            onClick={() => openLightbox(item, type)}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/placeholder-image.jpg';
            }}
          />
          <div className="position-absolute top-0 end-0 m-1">
            <Button
              variant="light"
              size="sm"
              className="rounded-circle p-1 d-flex align-items-center justify-content-center shadow-sm"
              style={{ width: '24px', height: '24px' }}
              onClick={(e) => {
                e.stopPropagation();
                downloadFile(item, type);
              }}
              title="Pobierz"
            >
              <Download size={14} />
            </Button>
          </div>
          <div className="position-absolute bottom-0 start-0 end-0 bg-dark bg-opacity-75 text-white p-1 small">
            {item.title || item.filename}
          </div>
        </div>
      );
    } else if (item.isPDF) {
      return (
        <div className="position-relative media-thumbnail">
          <div
            className="rounded shadow-sm bg-light d-flex align-items-center justify-content-center"
            style={{ width: '120px', height: '120px', cursor: 'pointer' }}
            onClick={() => openLightbox(item, type)}
          >
            <FileText size={48} className="text-primary" />
          </div>
          <div className="position-absolute top-0 end-0 m-1">
            <Button
              variant="light"
              size="sm"
              className="rounded-circle p-1 d-flex align-items-center justify-content-center shadow-sm"
              style={{ width: '24px', height: '24px' }}
              onClick={(e) => {
                e.stopPropagation();
                downloadFile(item, type);
              }}
              title="Pobierz"
            >
              <Download size={14} />
            </Button>
          </div>
          <div className="position-absolute bottom-0 start-0 end-0 bg-dark bg-opacity-75 text-white p-1 small">
            {item.title || item.filename}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderSection = (items, type) => {
    if (items.length === 0) return null;

    const title = type === 'photos' ? 'Zdjęcia' : 'Dokumenty';
    const itemsText = type === 'photos' ?
      `${items.length} ${items.length === 1 ? 'zdjęcie' : items.length < 5 ? 'zdjęcia' : 'zdjęć'}` :
      `${items.length} ${items.length === 1 ? 'dokument' : items.length < 5 ? 'dokumenty' : 'dokumentów'}`;

    return (
      <Col md={6} className="mb-4">
        <Card>
          <Card.Header className="bg-light">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">{title}</h5>
              <small className="text-muted">{itemsText}</small>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="d-flex flex-wrap gap-3">
              {items.map((item, index) => (
                <div key={index} className="media-item">
                  {renderThumbnail(item, type)}
                  {item.description && (
                    <div
                      className="mt-1 text-muted small text-center"
                      style={{
                        width: '120px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      title={item.description}
                    >
                      {item.description}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const renderLightboxContent = () => {
    const { item } = lightbox;
    if (!item) return null;

    if (item.isImage) {
      return (
        <div className="position-relative">
          <img
            src={lightbox.item.src}
            alt={lightbox.item.title}
            className="img-fluid"
            style={{ maxHeight: '80vh' }}
          />
          {lightbox.items.length > 1 && (
            <>
              <Button
                variant="light"
                className="position-absolute top-50 start-0 translate-middle-y ms-2 rounded-circle d-flex align-items-center justify-content-center"
                style={{ width: '40px', height: '40px', padding: 0 }}
                onClick={() => navigateMedia('prev')}
              >
                <ChevronLeft size={24} />
              </Button>
              <Button
                variant="light"
                className="position-absolute top-50 end-0 translate-middle-y me-2 rounded-circle d-flex align-items-center justify-content-center"
                style={{ width: '40px', height: '40px', padding: 0 }}
                onClick={() => navigateMedia('next')}
              >
                <ChevronRight size={24} />
              </Button>
            </>
          )}
        </div>
      );
    } else if (item.isPDF) {
      return (
        <div className="p-4 d-flex flex-column align-items-center">
          <FileText size={64} className="text-primary mb-3" />
          <div className="d-flex flex-wrap justify-content-center gap-2">
            <Button
              variant="primary"
              href={item.src}
              target="_blank"
              className="d-flex align-items-center gap-2"
            >
              <ExternalLink size={20} />
              Otwórz PDF
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => downloadFile(item, lightbox.type)}
              className="d-flex align-items-center gap-2"
            >
              <Download size={20} />
              Pobierz
            </Button>
            {lightbox.items.length > 1 && (
              <div className="d-flex gap-2 mt-3 w-100 justify-content-center">
                <Button
                  variant="light"
                  onClick={() => navigateMedia('prev')}
                  className="d-flex align-items-center gap-1"
                >
                  <ChevronLeft size={20} />
                  Poprzedni
                </Button>
                <Button
                  variant="light"
                  onClick={() => navigateMedia('next')}
                  className="d-flex align-items-center gap-1"
                >
                  Następny
                  <ChevronRight size={20} />
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  if (loading) {
    return (
      <div className="text-center py-5">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="d-flex align-items-center gap-2">
        <AlertCircle size={20} />
        {error}
      </Alert>
    );
  }

  if (media.photos.length === 0 && media.documents.length === 0) {
    return null;
  }

  return (
    <>
      <Row className="mt-4">
        <Col>
          <h3 className="mb-3">Media</h3>
        </Col>
      </Row>
      <Row>
        {renderSection(media.photos, 'photos')}
        {renderSection(media.documents, 'documents')}
      </Row>

      <Modal
        show={lightbox.show}
        onHide={() => setLightbox(prev => ({ ...prev, show: false }))}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center gap-2">
            <span>{lightbox.item?.title || lightbox.item?.filename}</span>
            {lightbox.items.length > 1 && (
              <small className="text-muted">
                ({lightbox.currentIndex + 1} z {lightbox.items.length})
              </small>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center p-0">
          {renderLightboxContent()}
        </Modal.Body>
        {lightbox.item?.description && (
          <Modal.Footer className="bg-light">
            <p className="text-muted mb-0">{lightbox.item.description}</p>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default MediaViewer;
