import React, { useState, useEffect } from 'react';
import { Form, Spinner, Modal, InputGroup, Badge } from 'react-bootstrap';
import { Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const TagsBrowser = () => {
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [recordings, setRecordings] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoadingRecordings, setIsLoadingRecordings] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadPublicTags();
  }, []);

  const loadPublicTags = async () => {
    try {
      // Najpierw pobierz listę publicznych nagrań
      const recordingsResponse = await fetch('/api/recordings');
      if (!recordingsResponse.ok) {
        throw new Error('Nie udało się załadować nagrań');
      }
      const recordingsData = await recordingsResponse.json();

      // Wyfiltruj ID publicznych nagrań
      const publicRecordingIds = recordingsData.data
        .filter(recording => recording.access?.restrictions === 'public')
        .map(recording => recording.metadata.signature);

      // Pobierz tagi z uwzględnieniem tylko publicznych nagrań
      const [keywordsResponse, personsResponse] = await Promise.all([
        fetch('/api/tags.php?type=keyword', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ publicRecordingIds })
        }),
        fetch('/api/tags.php?type=person', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ publicRecordingIds })
        })
      ]);

      if (!keywordsResponse.ok || !personsResponse.ok) {
        throw new Error('Nie udało się załadować tagów');
      }

      const [keywordsData, personsData] = await Promise.all([
        keywordsResponse.json(),
        personsResponse.json()
      ]);

      const allTags = [
        ...keywordsData.data.map(tag => ({ ...tag, type: 'keyword' })),
        ...personsData.data.map(tag => ({ ...tag, type: 'person' }))
      ].sort((a, b) => a.name.localeCompare(b.name));

      setTags(allTags);
    } catch (err) {
      setError('Wystąpił błąd podczas ładowania tagów: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTagClick = async (tag) => {
    setSelectedTag(tag);
    setIsLoadingRecordings(true);

    try {
      // Pobierz listę publicznych nagrań
      const recordingsResponse = await fetch('/api/recordings');
      const recordingsData = await recordingsResponse.json();
      const publicRecordingIds = recordingsData.data
        .filter(recording => recording.access?.restrictions === 'public')
        .map(recording => recording.metadata.signature);

      // Pobierz nagrania dla tagu z uwzględnieniem tylko publicznych
      const response = await fetch(`/api/tags.php?tag_id=${tag.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ publicRecordingIds })
      });

      if (!response.ok) throw new Error('Nie udało się załadować nagrań');
      const data = await response.json();

      // Pobierz szczegóły dla każdego nagrania
      const recordingsWithDetails = await Promise.all(
        data.data.map(async (item) => {
          const recordingResponse = await fetch(`/api/recordings/${item.recording_id}`, {
            credentials: 'include'
          });
          if (!recordingResponse.ok) return null;
          const recordingData = await recordingResponse.json();

          return {
            id: item.recording_id,
            timestamp: formatTime(item.start_time),
            details: recordingData.data
          };
        })
      );

      // Grupuj timestampy dla tych samych nagrań
      const groupedRecordings = recordingsWithDetails
        .filter(r => r !== null)
        .reduce((acc, recording) => {
          if (!acc[recording.id]) {
            acc[recording.id] = {
              id: recording.id,
              timestamps: [],
              details: recording.details
            };
          }
          acc[recording.id].timestamps.push(recording.timestamp);
          return acc;
        }, {});

      setRecordings(Object.values(groupedRecordings));
    } catch (err) {
      setError('Wystąpił błąd: ' + err.message);
    } finally {
      setIsLoadingRecordings(false);
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const getFilteredTags = () => {
    return tags.filter(tag =>
      !searchQuery || tag.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const calculateFontSize = (count) => {
    if (!count) return 'fs-5';
    if (count > 16) return 'fs-1';
    if (count > 12) return 'fs-2';
    if (count > 8) return 'fs-3';
    if (count > 4) return 'fs-4';
    if (count > 2) return 'fs-5';
    return 'fs-5';
  };

  const groupTagsByFirstChar = (tags) => {
    return tags.reduce((groups, tag) => {
      const firstChar = tag.name.charAt(0).toUpperCase();
      const groupChar = /^[0-9]/.test(firstChar) ? '#' : firstChar;

      if (!groups[groupChar]) {
        groups[groupChar] = [];
      }
      groups[groupChar].push(tag);
      return groups;
    }, {});
  };

  const getTagStyle = (tag) => {
    const baseStyle = {
      cursor: 'pointer',
      transition: 'all 0.2s ease'
    };

    if (tag.type === 'person') {
      return {
        ...baseStyle,
        color: '#2E7D32'
      };
    }

    return {
      ...baseStyle,
      color: '#1976D2'
    };
  };

  const renderTagList = () => {
    const filteredTags = getFilteredTags();
    const groupedTags = groupTagsByFirstChar(filteredTags);
    const sortedGroups = Object.keys(groupedTags).sort((a, b) => {
      if (a === '#') return -1;
      if (b === '#') return 1;
      return a.localeCompare(b);
    });

    return (
      <div className="row fs-4">
        <div className="col-lg-1"></div>
        <div className="col-lg-10 mx-auto d-flex flex-wrap gap-3 align-items-baseline">
          {sortedGroups.map((group) => (
            <React.Fragment key={group}>
              <span className="text-muted fw-bold align-self-center px-2 border-end">
                {group}
              </span>
              {groupedTags[group].map((tag) => (
                <span
                  key={tag.id}
                  onClick={() => handleTagClick(tag)}
                  className={`${calculateFontSize(tag.recordings_count)}`}
                  style={getTagStyle(tag)}
                >
                  {tag.name}
                  {tag.recordings_count > 0 && (
                    <span className="text-muted ms-1">({tag.recordings_count})</span>
                  )}
                </span>
              ))}
            </React.Fragment>
          ))}
          {sortedGroups.length === 0 && (
            <div className="text-muted">
              {searchQuery ? 'Brak wyników dla podanej frazy' : 'Brak dostępnych tagów'}
            </div>
          )}
        </div>
        <div className="col-lg-1"></div>
      </div>
    );
  };

  const TagRecordingsList = () => {
    if (isLoadingRecordings) {
      return (
        <div className="text-center p-4">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Ładowanie...</span>
          </Spinner>
        </div>
      );
    }

    if (recordings.length === 0) {
      return <p className="text-muted text-center">Brak nagrań z tym tagiem</p>;
    }

    return (
      <div className="list-group">
        {recordings.map(recording => (
          <div key={recording.id} className="list-group-item">
            <div className="d-flex justify-content-between align-items-start">
              <div>
                <h6 className="mb-1">{recording.details.metadata.interviewee?.name}</h6>
                <p className="mb-1 text-muted small">{recording.details.metadata.title}</p>
                <div>
                  {recording.timestamps.sort().map((timestamp, index) => (
                    <Badge
                      key={index}
                      bg="primary"
                      className="me-1 cursor-pointer"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const [hours, minutes, seconds] = timestamp.split(':').map(Number);
                        const timeInSeconds = hours * 3600 + minutes * 60 + seconds;
                        navigate(`/library/recording/${recording.id}`, {
                          state: { initialTime: timeInSeconds }
                        });
                      }}
                    >
                      {timestamp}
                    </Badge>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="text-center mt-4">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Ładowanie...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div>
      {error && (
        <div className="alert alert-danger">
          {error}
        </div>
      )}

      <div className="mb-4">
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            placeholder="Szukaj ..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <InputGroup.Text>
            <Search size={20} />
          </InputGroup.Text>
        </InputGroup>

        <div className="d-flex gap-3 fs-4 align-items-center">
          <div style={{ color: '#1976D2' }} className="fw-black badge border d-flex align-items-center gap-2">
            <span>●</span>
            <span>Słowa kluczowe</span>
          </div>
          <div style={{ color: '#2E7D32' }} className="fw-black badge border d-flex align-items-center gap-2">
            <span>●</span>
            <span>Osoby</span>
          </div>
        </div>
      </div>

      {renderTagList()}

      <Modal show={selectedTag !== null} onHide={() => setSelectedTag(null)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex align-items-center gap-2">
              <span className={`badge ${selectedTag?.type === 'keyword' ? 'bg-primary' : 'bg-success'}`}>
                {selectedTag?.type === 'keyword' ? 'Słowo kluczowe' : 'Osoba'}
              </span>
              <span>{selectedTag?.name}</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TagRecordingsList />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TagsBrowser;
