import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { aboutContent, aboutPartnersContent } from '../content/about';
import { wcagContent } from '../content/wcag';
import { termsContent } from '../content/terms';
import { privacyContent, contactContent, footerContent } from '../content/pages';
import LibraryMap from './LibraryMap';

import { PageTitle } from '../common/PageTitle';

export function Footer() {
  return (
    <footer className="bg-secondary-subtle mt-5 py-4">
      <Container>
        <Row>
          <Col md={3}>
            <h5><a href="https://biblioteka-pomiechowek.pl/"
                  className="text-decoration-none"
                  target="_blank"
                  rel="noopener noreferrer"
	  >
	  {footerContent.address.name}</a>
	  </h5>
            <p>
              {footerContent.address.street}<br />
              {footerContent.address.city}<br />
              Tel: {footerContent.address.phone}
            </p>
          </Col>
          <Col md={3}>
	  {/*<h5>Śledź nas</h5>*/}
            <div className="d-flex gap-3">
              {footerContent.libsocial.map((item, index) => (
                <a
                  key={index}
                  href={item.url}
                  className="text-decoration-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.label}
                </a>
              ))}
            </div>
            <img src="/img/biblioteka-logo-czarne.png" className="w-25 img-fluid" alt="Biblioteka Logo" />
          </Col>
          <Col md={3}>
            <h5>Nawigacja</h5>
            <ul className="list-unstyled">
              {footerContent.navigation.map((item, index) => (
                <li key={index}>
                  <Link to={item.path}
		        onClick={() => window.scrollTo(0, 0)}
		      className="text-decoration-none">
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col md={3}>
            <div className="d-flex gap-3">
              {footerContent.social.map((item, index) => (
                <a
                  key={index}
                  href={item.url}
                  className="text-decoration-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.label}
                </a>
              ))}
            </div>
            <img src="/img/pbhm-logo-3.svg" className="w-50 img-fluid" alt="PBHM Logo" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
            <p className="mb-0">
              © {new Date().getFullYear()} Pomiechowska Biblioteka Historii Mówionej. Wszelkie prawa zastrzeżone.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}


export function Wcag() {
  return (
    <Container className="py-5">
      <Row>
        <Col>
          <div dangerouslySetInnerHTML={{ __html: wcagContent }} />
        </Col>
      </Row>
    </Container>
  );
}

export function About() {
  return (
    <>
    <PageTitle title="O projekcie" />
    <Container className="py-5">
      <h1 className="mb-4">O projekcie</h1>
      <Row>
        <Col lg={6}>
	<div className="w-100 text-center">
	    <img
                  src="/img/pbhm-logo-3.svg"
                  alt="PBHM logo"
                  className="w-50 img-fluid"
                />
</div></Col><Col lg={6}/>
	  </Row>
	  <Row>
        <Col>
          <div dangerouslySetInnerHTML={{ __html: aboutContent }} />
	  <hr/>
          <div dangerouslySetInnerHTML={{ __html: aboutPartnersContent }} />
        </Col>
      </Row>
    </Container>
    </>
  );
}

export function Contact() {
  return (
    <>
    <PageTitle title="Kontakt" />
    <Container className="py-5">
      <h1 className="mb-4">Kontakt</h1>
      <Row>
        <Col md={6}>
          <div dangerouslySetInnerHTML={{ __html: contactContent }} />
        </Col>
        <Col md={6}>
          <div style={{ height: '400px' }}>
            <LibraryMap />
          </div>
        </Col>
      </Row>
    </Container>
    </>
  );
}

export function Privacy() {
  return (
    <Container className="py-5">
      <h1 className="mb-4">Polityka prywatności</h1>
      <div dangerouslySetInnerHTML={{ __html: privacyContent }} />
    </Container>
  );
}

export function Terms() {
  return (
    <Container className="py-5">
      <div
	          className="terms-wrapper"
	  dangerouslySetInnerHTML={{ __html: termsContent }} />
    </Container>
  );
}
