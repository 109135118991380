import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, Button, Offcanvas } from 'react-bootstrap';
import { Tags } from 'lucide-react';
import { useParams, useLocation } from 'react-router-dom';
import { Howl } from 'howler';
import TagListAudio from './TagListAudio';
import TranscriptionView from './TranscriptionView';
import AudioPlayerControls from './AudioPlayerControls';

const RecordingPlayer = () => {
  const { id } = useParams();
  const location = useLocation();
  const initialTime = location.state?.initialTime || 0;
  const [recording, setRecording] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(initialTime);
  const [duration, setDuration] = useState(0);
  const [currentTranscript, setCurrentTranscript] = useState(null);
  const [transcription, setTranscription] = useState([]);
  const [subtitleTags, setSubtitleTags] = useState({});
  const transcriptionContainerRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentMatchIndex, setCurrentMatchIndex] = useState(-1);
  const [matches, setMatches] = useState([]);
  const [showTagsSidebar, setShowTagsSidebar] = useState(false);

  const soundRef = useRef(null);
  const seekInterval = useRef(null);

  const parseTimeString = useCallback((timeString) => {
    const [time, ms] = timeString.split(',');
    const [hours, minutes, seconds] = time.split(':');
    return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds) + parseInt(ms) / 1000;
  }, []);

  const parseSRT = useCallback((content) => {
    const subtitles = content.trim().split('\n\n').map(block => {
      const lines = block.split('\n');
      const [startTime, endTime] = lines[1].split(' --> ');
      return {
        id: parseInt(lines[0]),
        start: parseTimeString(startTime),
        end: parseTimeString(endTime),
        text: lines.slice(2).join('\n')
      };
    });
    return subtitles;
  }, [parseTimeString]);

  const fetchTranscription = useCallback(async () => {
    if (!recording) return;
    try {
      const audioFile = recording.technical.storage[0];
      const transcriptFile = recording.technical.transcripts.find(t => t.audioFile === audioFile)?.srtFile;
      if (!transcriptFile) return;

      const response = await fetch(`/api/getsrt.php?id=${recording.metadata.signature}&filename=${transcriptFile}`);
      const text = await response.text();
      const parsed = parseSRT(text);
      setTranscription(parsed);
    } catch (error) {
      console.error('Error fetching transcription:', error);
    }
  }, [recording, parseSRT]);

  const loadSubtitleTags = useCallback(async () => {
    if (!recording) return;
    try {
      const audioFile = recording.technical.storage[0];
      const transcriptFile = recording.technical.transcripts.find(t => t.audioFile === audioFile)?.srtFile;
      if (!transcriptFile) return;

      const response = await fetch(`/api/tags.php?recording_id=${id}&transcript_file=${transcriptFile}`);
      if (!response.ok) throw new Error('Failed to load tags');
      const data = await response.json();

      const tagsByStartTime = {};
      data.data.forEach(tag => {
        if (!tagsByStartTime[tag.start_time]) {
          tagsByStartTime[tag.start_time] = [];
        }
        tagsByStartTime[tag.start_time].push(tag);
      });

      setSubtitleTags(tagsByStartTime);
    } catch (error) {
      console.error('Error loading tags:', error);
    }
  }, [id, recording]);

  const scrollToSubtitle = useCallback((subtitle) => {
    const element = document.getElementById(`transcript-${subtitle.id}`);
    if (element && transcriptionContainerRef.current) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  // Fetch recording data
  useEffect(() => {
    const fetchRecording = async () => {
      try {
        const response = await fetch(`/api/recordings/${id}`);
        const data = await response.json();
        setRecording(data.data);
      } catch (error) {
        console.error('Error fetching recording:', error);
      }
    };

    fetchRecording();
  }, [id]);

  // Initialize Howler
  useEffect(() => {
    if (recording) {
      if (soundRef.current) {
        soundRef.current.unload();
      }

      soundRef.current = new Howl({
        src: [`/storage/recordings/${recording.metadata.signature}/${recording.technical.storage[0]}`],
        html5: true,
        onload: () => {
          setDuration(soundRef.current.duration());
          soundRef.current.seek(initialTime);
          setCurrentTime(initialTime);
        },
        onplay: () => {
          setIsPlaying(true);
          seekInterval.current = setInterval(() => {
            setCurrentTime(soundRef.current.seek());
          }, 100);
        },
        onpause: () => {
          setIsPlaying(false);
          if (seekInterval.current) {
            clearInterval(seekInterval.current);
          }
        },
        onstop: () => {
          setIsPlaying(false);
          if (seekInterval.current) {
            clearInterval(seekInterval.current);
          }
        },
        onend: () => {
          setIsPlaying(false);
          if (seekInterval.current) {
            clearInterval(seekInterval.current);
          }
        },
        onseek: () => {
          setCurrentTime(soundRef.current.seek());
        }
      });

      return () => {
        if (soundRef.current) {
          soundRef.current.unload();
        }
        if (seekInterval.current) {
          clearInterval(seekInterval.current);
        }
      };
    }
  }, [recording, initialTime]);

  // Load transcription and tags
  useEffect(() => {
    if (recording) {
      fetchTranscription();
      loadSubtitleTags();
    }
  }, [recording, fetchTranscription, loadSubtitleTags]);

  // Update current transcript based on time
  useEffect(() => {
    if (transcription.length > 0) {
      const subtitle = transcription.find(
        sub => currentTime >= sub.start && currentTime < sub.end
      );
      if (subtitle && subtitle.id !== currentTranscript?.id) {
        setCurrentTranscript(subtitle);
        scrollToSubtitle(subtitle);
      }
    }
  }, [currentTime, transcription, currentTranscript?.id, scrollToSubtitle]);

  const handlePlayPause = useCallback(() => {
    if (soundRef.current) {
      if (isPlaying) {
        soundRef.current.pause();
      } else {
        soundRef.current.play();
      }
    }
  }, [isPlaying]);

  const handleSkip = useCallback((seconds) => {
    if (soundRef.current) {
      const currentSeek = soundRef.current.seek();
      const newTime = Math.max(0, Math.min(duration, currentSeek + seconds));
      soundRef.current.seek(newTime);
    }
  }, [duration]);

  const handleTimeUpdate = useCallback((newTime) => {
    if (soundRef.current) {
      soundRef.current.seek(newTime);
    }
  }, []);

  const handleTranscriptClick = useCallback((subtitle) => {
    if (soundRef.current) {
      soundRef.current.seek(subtitle.start);
      setCurrentTime(subtitle.start);
      setCurrentTranscript(subtitle);

      if (!isPlaying) {
        soundRef.current.play();
      }
    }
  }, [isPlaying]);

  const handleTagTimeSelect = useCallback((startTime) => {
    if (soundRef.current) {
      soundRef.current.seek(startTime);
      setCurrentTime(startTime);
      
      const subtitle = transcription.find(
        sub => startTime >= sub.start && startTime < sub.end
      );
      
      if (subtitle) {
        setCurrentTranscript(subtitle);
        
        setTimeout(() => {
          const element = document.getElementById(`transcript-${subtitle.id}`);
          if (element && transcriptionContainerRef.current) {
            element.scrollIntoView({ 
              behavior: 'smooth', 
              block: 'center'
            });
            
            element.classList.add('highlight-transition');
            setTimeout(() => {
              element.classList.remove('highlight-transition');
            }, 1000);
          }
        }, 100);
      }
      setShowTagsSidebar(false);
    }
  }, [transcription]);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    if (!query.trim()) {
      setMatches([]);
      setCurrentMatchIndex(-1);
      return;
    }

    const matchingSubtitles = transcription.reduce((acc, subtitle, index) => {
      if (subtitle.text.toLowerCase().includes(query.toLowerCase())) {
        acc.push({ ...subtitle, index });
      }
      return acc;
    }, []);

    setMatches(matchingSubtitles);
    if (matchingSubtitles.length > 0) {
      setCurrentMatchIndex(0);
      const firstMatch = matchingSubtitles[0];
      scrollToSubtitle(firstMatch);
      setCurrentTranscript(firstMatch);
    }
  }, [transcription, scrollToSubtitle]);

  const handleNextMatch = useCallback(() => {
    if (matches.length === 0) return;
    const nextIndex = (currentMatchIndex + 1) % matches.length;
    setCurrentMatchIndex(nextIndex);
    scrollToSubtitle(matches[nextIndex]);
    setCurrentTranscript(matches[nextIndex]);
  }, [matches, currentMatchIndex, scrollToSubtitle]);

  const handlePreviousMatch = useCallback(() => {
    if (matches.length === 0) return;
    const prevIndex = currentMatchIndex <= 0 ? matches.length - 1 : currentMatchIndex - 1;
    setCurrentMatchIndex(prevIndex);
    scrollToSubtitle(matches[prevIndex]);
    setCurrentTranscript(matches[prevIndex]);
  }, [matches, currentMatchIndex, scrollToSubtitle]);

  if (!recording) return null;

  return (
    <div className="container-fluid px-4">
      <div className="d-flex justify-content-end align-items-center mb-4">
        <Button
          variant="outline-primary"
          className="d-lg-none d-flex align-items-center gap-2"
          onClick={() => setShowTagsSidebar(true)}
        >
          <Tags size={20} />
          <span>Tagi</span>
        </Button>
      </div>

      <div className="row">
        <div className="col-12 col-lg-8 border">
          <small className="rozmowca mb-2">
            {recording.metadata.interviewee?.name} – {recording.metadata.date}
          </small>

          <AudioPlayerControls
            isPlaying={isPlaying}
            currentTime={currentTime}
            duration={duration}
            onPlayPause={handlePlayPause}
            onSkip={handleSkip}
            onTimeUpdate={handleTimeUpdate}
            waveformVisible={true}
            soundRef={soundRef}
          />

          <TranscriptionView
            transcription={transcription}
            currentTranscript={currentTranscript}
            searchQuery={searchQuery}
            matches={matches}
            currentMatchIndex={currentMatchIndex}
            onSearch={handleSearch}
            onNextMatch={handleNextMatch}
            onPreviousMatch={handlePreviousMatch}
            onTranscriptClick={handleTranscriptClick}
            transcriptionRef={transcriptionContainerRef}
            subtitleTags={subtitleTags}
          />
        </div>

        <div className="d-none d-lg-block col-lg-4">
          <Card className="mb-4 border">
            <Card.Body>
              <TagListAudio
                recordingId={id}
                transcriptFile={recording.technical.transcripts.find(
                  t => t.audioFile === recording.technical.storage[0]
                )?.srtFile}
                onTimeSelect={handleTagTimeSelect}
              />
            </Card.Body>
          </Card>
        </div>

        <Offcanvas
          show={showTagsSidebar}
          onHide={() => setShowTagsSidebar(false)}
          placement="end"
          className="d-lg-none"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Tagi i słowa kluczowe</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <TagListAudio
              recordingId={id}
              transcriptFile={recording.technical.transcripts.find(
                t => t.audioFile === recording.technical.storage[0]
              )?.srtFile}
              onTimeSelect={handleTagTimeSelect}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};

export default RecordingPlayer;
