export const wcagContent = `
  <div class="container fs-5 mx-auto px-4 py-8">
    <div class="accessibility-declaration prose max-w-none about-content">
    <h1>Deklaracja dostępności</h1>

    <section aria-labelledby="intro">
        <h2 id="intro">Wstęp</h2>
        <p>Biblioteka Publiczna Gminy Pomiechówek
	zobowiązuje się zapewnić dostępność strony internetowej
	projektu "Pomiechowska Biblioteka Historii Mówioniej" zgodnie
	z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej
	stron internetowych i aplikacji mobilnych podmiotów
	publicznych. Niniejsza deklaracja dostępności dotyczy strony
	internetowej <a href="/">pbhm.biblioteka-pomiechowek.pl</a>.</p>
        <p>Data publikacji strony internetowej: 28 grudnia 2024&nbsp;r.</p>
        <p>Data ostatniej aktualizacji: 30 grudnia 2024&nbsp;r.</p>
    </section>

    <section aria-labelledby="status">
        <h2 id="status">Status pod względem zgodności z ustawą</h2>
	<p>Strona internetowa jest częściowo zgodna z ustawą o
	dostępności cyfrowej stron internetowych i aplikacji mobilnych
	podmiotów publicznych z powodu niezgodności lub wyłączeń
	wymienionych poniżej.</p>
    </section>

    <section aria-labelledby="preparation">
        <h2 id="preparation">Przygotowanie deklaracji dostępności</h2>
        <ul>
            <li>Niniejsza deklaracja została przygotowana dnia 27 grudnia 2024&nbsp;r..</li>
            <li>Metoda przygotowania deklaracji: samoocena przeprowadzona przez podmiot publiczny.</li>
            <li>Data ostatniego przeglądu deklaracji: 28 grudnia 2024&nbsp;r.</li>
        </ul>
    </section>

    <section aria-labelledby="contact">
        <h2 id="contact">Informacje zwrotne i dane kontaktowe</h2>
	<p>W przypadku problemów z dostępnością strony internetowej
	prosimy o kontakt z Katarzyną Urbaś.</p>
	<p>Kontakt: <a
	href="mailto:dyrektor@biblioteka-pomiechowek.pl">dyrektor@biblioteka-pomiechowek.pl</a>,
	tel. <a href="tel:0048227854697">22 785-46-97</a></p>
	<p>Tą samą drogą można składać wnioski o udostępnienie
	informacji niedostępnej oraz składać skargi na brak zapewnienia
	dostępności.</p>
    </section>

    <section aria-labelledby="procedure">
        <h2 id="procedure">Informacje na temat procedury</h2>
	<p>Każdy ma prawo do wystąpienia z żądaniem zapewnienia
	dostępności cyfrowej strony internetowej lub jakiegoś jej
	elementu. Można także zażądać udostępnienia informacji w
	formach alternatywnych, na przykład odczytania niedostępnego
	cyfrowo dokumentu, opisania zawartości filmu bez audiodeskrypcji
	itp.</p>
        <p>Żądanie powinno zawierać:</p>
        <ul>
            <li>dane osoby zgłaszającej żądanie</li>
            <li>wskazanie strony internetowej, której dotyczy żądanie</li>
            <li>sposób kontaktu</li>
	    <li>jeśli osoba żądająca zgłasza potrzebę otrzymania
	    informacji w formie alternatywnej, powinna także określić
	    formę tej informacji</li>
        </ul>
    </section>

    <section aria-labelledby="additional">
        <h2 id="additional">Informacje dodatkowe</h2>
        <p>Strona posiada następujące ułatwienia dla osób z niepełnosprawnościami:</p>
        <ul>
            <li>możliwość zmiany rozmiaru tekstu</li>
            <li>wyraźny kontrast tekstu do tła</li>
            <li>możliwość nawigacji za pomocą klawiatury</li>
            <li>opisy alternatywne grafik i zdjęć</li>
            <li>możliwość zatrzymania animacji</li>
        </ul>
    </section>

    <section aria-labelledby="address">
        <h2 id="address">Dane teleadresowe jednostki</h2>
        <address>
            <p>Biblioteka Publiczna Gminy Pomiechówek</p>
            <p>ul. Jana Kilińskiego 3<br/> 05-180 Pomiechówek</p>
            <p>Tel.: <a href="tel:0048227854697">22 785-46-97</a></p>
            <p>E-mail: <a href="mailto:biblioteka@pomiechowek.pl">biblioteka@pomiechowek.pl</a></p>
            <p>Strona internetowa: <a href="https://biblioteka-pomiechowek.pl">biblioteka-pomiechowek.pl</a></p>
        </address>
    </section>
    </div>
</div>
`;

