import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const MediaEditModal = ({ show, file, onHide, onSubmit }) => {
  const [formData, setFormData] = React.useState({
    title: '',
    description: ''
  });

  React.useEffect(() => {
    if (file) {
      setFormData({
        title: file.title || '',
        description: file.description || ''
      });
    }
  }, [file]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSubmit(formData);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edytuj szczegóły</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Tytuł</Form.Label>
            <Form.Control
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({
                ...formData,
                title: e.target.value
              })}
              placeholder="Tytuł pliku"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Opis</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={formData.description}
              onChange={(e) => setFormData({
                ...formData,
                description: e.target.value
              })}
              placeholder="Opis pliku"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Anuluj
          </Button>
          <Button variant="primary" type="submit">
            Zapisz zmiany
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default MediaEditModal;
