import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Form, Button, Alert, Card, Container, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';

function Login({ setIsAuthenticated, setUserRole, mode = 'normal' }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [showTokenInput, setShowTokenInput] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const emailInputRef = useRef(null);

  const verifyToken = useCallback(async (tokenToVerify) => {
    setIsLoading(true);
    try {
      const response = await fetch('/auth-api/verify-2fa', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: tokenToVerify }),
        credentials: 'include'
      });

      const data = await response.json();

      if (response.ok) {
        setIsAuthenticated(true);
        setUserRole(data.role);
        setError('');
        navigate('/');
      } else {
        setError(data.error);
        if (mode === 'verify-link') {
          navigate('/login');
        }
      }
    } catch (err) {
      setError('Weryfikacja nie powiodła się. Spróbuj ponownie.');
      if (mode === 'verify-link') {
        navigate('/login');
      }
    } finally {
      setIsLoading(false);
    }
  }, [setIsAuthenticated, setUserRole, navigate, mode]);

  useEffect(() => {
    if (mode === 'verify-link') {
      const urlParams = new URLSearchParams(window.location.search);
      const urlToken = urlParams.get('token');
      if (urlToken) {
        setIsLoading(true);
        verifyToken(urlToken);
      }
    } else if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [mode, verifyToken]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch('/auth-api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
        credentials: 'include'
      });

      const data = await response.json();

      if (response.ok) {
        setShowTokenInput(true);
        setError('');
      } else {
        setError(data.error);
      }
    } catch (err) {
      setError('Logowanie nie powiodło się. Spróbuj ponownie.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerify2FA = async (e) => {
    e.preventDefault();
    await verifyToken(token);
  };

  // Jeśli jest to weryfikacja z linku, pokazujemy tylko informację o ładowaniu
  if (mode === 'verify-link') {
    return (
      <Container className="vh-100">
        <Row className="h-100 align-items-center justify-content-center">
          <Col xs={12} sm={8} md={6} lg={4}>
            <Card className="shadow-lg border-0">
              <Card.Header className="bg-primary text-white text-center py-3">
                <h3 className="mb-0">Weryfikacja</h3>
              </Card.Header>
              <Card.Body className="p-4 text-center">
                {error && <Alert variant="danger">{error}</Alert>}
                {isLoading && (
                  <>
                    <Spinner
                      animation="border"
                      role="status"
                      className="mb-3"
                    />
                    <p>Trwa weryfikacja, proszę czekać...</p>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  // Standardowy widok dla normalnego logowania
  return (
    <Container className="vh-100">
      <Row className="h-100 align-items-center justify-content-center">
        <Col xs={12} sm={8} md={6} lg={4}>
          <Card className="shadow-lg border-0">
            <Card.Header className="bg-primary text-white text-center py-3">
              <h3 className="mb-0">Login</h3>
            </Card.Header>
            <Card.Body className="p-4">
              {error && <Alert variant="danger">{error}</Alert>}

              {!showTokenInput ? (
                <>
                  <Form onSubmit={handleLogin}>
                    <Form.Group className="mb-4">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="Podaj email"
                        ref={emailInputRef}
                        disabled={isLoading}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Hasło</Form.Label>
                      <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Podaj hasło"
                        disabled={isLoading}
                      />
                    </Form.Group>

                    <Button
                      type="submit"
                      className="w-100 mb-3"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                          Logowanie...
                        </>
                      ) : (
                        'Login'
                      )}
                    </Button>
                  </Form>
                  <div className="text-center">
                    <Link to="/reset-password" className="text-decoration-none">Zapomniane hasło?</Link>
                    {' '} – {' '}
                    <Link to="/register" className="text-decoration-none">Rejestracja</Link>
                  </div>
                </>
              ) : (
                <Form onSubmit={handleVerify2FA}>
                  <Form.Group className="mb-4">
                    <Form.Label>Podaj kod, który wysłaliśmy mailem lub kliknij w link w wiadomości</Form.Label>
                    <Form.Control
                      type="text"
                      value={token}
                      onChange={(e) => setToken(e.target.value)}
                      required
                      placeholder="Podaj kod weryfikacyjny"
                      disabled={isLoading}
                    />
                  </Form.Group>

                  <Button
                    type="submit"
                    className="w-100"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        Weryfikacja...
                      </>
                    ) : (
                      'Weryfikacja'
                    )}
                  </Button>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
